import React, { useEffect, useRef, useState } from "react";
import SecondaryButton from "../../components/Common/Buttons/SecondaryButton";
import Tipping from "./Tipping";
import IconTwitter from "../../../images/icons/icon_twitter.png";
import IconLine from "../../../images/icons/icon_line.png";
import IconFacebook from "../../../images/icons/icon_facebook.png";
import URLCopyModal from "./URLCopyModal";
import QRCodeModal from "./QRCodeModal";
import WidgetModal from "./WidgetModal";
import { projectStatusCodes } from "../../utils/constants";
import { calcTargetAmountPercentage, calcProgressingBarPercentage } from "../../utils/progressingUtil"

type Props = {
  investmentID: number;
  investmentProgressingSum: number;
  pendingAmount: number;
  targetAmount: number;
  nextTargetAmount?: number;
  supportCount: number;
  supporterCount: number;
  isProjectActive: boolean;
  remainingPeriod: number;
  beginDateTime: string;
  endDateTime: string;
  beginDate: string;
  endDate: string;
  paymentDueDate: string;
  referralURL: string;
  widgetURL: string;
  investmentTitle: string;
  projectStatusCode: number;
  canTip: boolean;
  isLoggedIn: boolean;
  cardRegistered: boolean;
  payJpPublicKey: string;
};

const Progress: React.FC<Props> = (props) => {
  const {
    investmentProgressingSum = 0,
    targetAmount = 0,
    pendingAmount = 0,
    nextTargetAmount,
    supportCount,
    supporterCount,
    isProjectActive,
    remainingPeriod,
    beginDateTime,
    endDateTime,
    beginDate,
    endDate,
    paymentDueDate,
    referralURL,
    widgetURL,
    investmentTitle,
    projectStatusCode,
    canTip,
    isLoggedIn,
    cardRegistered,
    payJpPublicKey
  } = props;

  const progressPointer = useRef(null);
  const progress = useRef(null);
  const [isURLCopyModalActive, setIsURLCopyModalActive] = useState<boolean>(false)
  const [isQRCodeModalActive, setIsQRCodeModalActive] = useState<boolean>(false)
  const [isWidgetModalActive, setIsWidgetModalActive] = useState<boolean>(false)
  const [investmentTotalAmount, setInvestmentTotalAmount] = useState<number>(investmentProgressingSum);

  useEffect(() => {
    let percentage
    if (nextTargetAmount) {
      percentage = calcTargetAmountPercentage(
        investmentTotalAmount,
        nextTargetAmount
      );
    } else {
      percentage = calcTargetAmountPercentage(
        investmentTotalAmount,
        targetAmount
      );
    }
    progressPointer.current.style.left = `min(calc(${percentage}% - 3px), calc(100% - 3px))`;

    if (percentage > 82) {
      progress.current.style.right = 0;
    } else {
      progress.current.style.left = `clamp(0%, calc(${percentage}% - 30px), 75%)`;
    }
  }, [investmentTotalAmount]);

  const toggleURLCopyModal = () => {
    setIsURLCopyModalActive(!isURLCopyModalActive)
  }

  const toggleQRCodeModal = () => {
    setIsQRCodeModalActive(!isQRCodeModalActive)
  }

  const openURLCopyModal = () => {
    toggleURLCopyModal()
    navigator.clipboard.writeText(referralURL)
  }

  const toggleWidgetModal = () => {
    setIsWidgetModalActive(!isWidgetModalActive)
  }

  return (
    <>
      <div className="border-[#D2DFE6] sm:w-[360px] border-b border-t">
        <div className="py-[30px] sm:pt-[50px] sm:pb-5">
          <dl className="mb-[10px] text-center sm:mb-6">
            <dt className="font-bold">現在の支援金額</dt>
            <dd className="text-[34px] font-bold">
              {investmentTotalAmount.toLocaleString()}
              <span className="text-xl">円</span>
            </dd>
            {/*
              {projectStatusCode === projectStatusCodes.PROJECT_STATUS_END && pendingAmount > 0 && (
                <div className="text-xl">
                  {`（${paymentDueDate} 最終金額確定予定）`}
                </div>
              )}
            */}
          </dl>
          { nextTargetAmount ? (
            <>
              <dl className="flex justify-center">
                <dt className="text-[#768084] mr-1">NEXT GOAL</dt>
                <dd>{nextTargetAmount.toLocaleString()}円</dd>
              </dl>
              <dl className="flex justify-center">
                <dt className="text-[#768084] mr-1">(第一目標金額</dt>
                <dd>{targetAmount.toLocaleString()}円)</dd>
              </dl>
            </>
          ) : (
            <dl className="flex justify-center">
              <dt className="text-[#768084] mr-1">目標金額</dt>
              <dd>{targetAmount.toLocaleString()}円</dd>
            </dl>
          )}
          <div className="sm:mt-[50px] relative mt-9">
            <div
              className="top-[-30px] absolute inline-block font-raleway text-sm"
              ref={progress}
            >
              {calcTargetAmountPercentage(investmentTotalAmount, targetAmount)}
              % Funded
            </div>
            <div
              className="top-[-10px] border-l-[3px] border-r-[3px] border-t-[10px] border-t-[#1F2122] bottom-[-6px] absolute inline-block w-0 h-0 border-l-transparent border-r-transparent"
              ref={progressPointer}
            ></div>
            <div className="bg-[#D2DFE6] h-[6px] relative flex items-center">
              <div
                className="bg-[#1F2122] h-[8px]"
                style={{
                  width: `${calcProgressingBarPercentage(investmentTotalAmount, targetAmount, nextTargetAmount)[0]}%`,
                }}
              ></div>
              {nextTargetAmount && (
                <div
                  className={`bg-[#768084] h-[8px] absolute top-[-1px]`}
                  style={{
                    width: `${calcProgressingBarPercentage(investmentTotalAmount, targetAmount, nextTargetAmount)[1]}%`,
                    left: `${calcProgressingBarPercentage(investmentTotalAmount, targetAmount, nextTargetAmount)[0]}%`
                  }}
                ></div>
              )}
            </div>
          </div>
          <div className="mt-3">
            { canTip && (
              <Tipping
                projectID={props.investmentID}
                setInvestmentTotalAmount={setInvestmentTotalAmount}
                canTip={canTip}
                isLoggedIn={isLoggedIn}
                cardRegistered={cardRegistered}
                payJpPublicKey={payJpPublicKey}
              />
            )}
          </div>
          <div className="mt-4">
            <dl className="flex justify-between mb-1">
              <dt className="text-[#768084] text-sm left-icon icon-human flex items-center">支援者数</dt>
              <dd>{supporterCount}人</dd>
            </dl>
            <dl className="flex justify-between mb-1">
              <dt className="text-[#768084] text-sm left-icon icon-human flex items-center">支援数</dt>
              <dd>{supportCount}件</dd>
            </dl>
            <dl className="flex justify-between">
              <dt className="text-sm text-[#768084] left-icon icon-clock-grey flex items-center">残り時間</dt>
              {isProjectActive ? (
                remainingPeriod === 0 ? (
                  <dd className="text-red-500">本日最終日({endDateTime.split(' ').pop()}終了)</dd>
                ) : (
                  <dd>{remainingPeriod}日({endDateTime}終了)</dd>
                )
              ) : (
                projectStatusCode === projectStatusCodes.PROJECT_STATUS_COMING_SOON ? (
                  <dd>{beginDateTime}開始</dd>
                ): (
                  <dd>募集は終了しました</dd>
                )
              )}
            </dl>
            {projectStatusCode === projectStatusCodes.PROJECT_STATUS_END && (
              <dl className="flex justify-between">
                <dt className="text-sm text-[#768084] left-icon icon-calender flex items-center">募集期間</dt>
                <dd>{beginDate}〜{endDate}</dd>
              </dl>
            )}
          </div>
        </div>
        <div className="py-[30px] border-[#D2DFE6] border-t sm:py-5">
          <div className="flex items-center justify-center text-center text-sm font-bold">
            このプロジェクトを紹介してプレゼントをGET!
          </div>
          <div className="sm:flex sm:items-center">
            <ul className="my-[15px] flex items-center justify-center sm:mr-5">
              <li className="w-[25px] h-[25px] mr-5">
                <a href={`http://www.facebook.com/share.php?quote=${investmentTitle}&u=${referralURL}`} className="link-hover-none inline-block">
                  <img src={IconFacebook} alt="facebook" />
                </a>
              </li>
              <li className="w-[24px] h-[25px] mr-5">
                <a href={`http://twitter.com/share?url="${referralURL}"&text=${investmentTitle}`} className="link-hover-none inline-block">
                  <img src={IconTwitter} alt="twitter" />
                </a>
              </li>
              <li className="w-[25px] h-[25px]">
                <a href={`https://social-plugins.line.me/lineit/share?url=${referralURL}`} className="link-hover-none inline-block">
                  <img src={IconLine} alt="line" />
                </a>
              </li>
            </ul>
            <div className="flex justify-center">
              <div className="mr-1 w-[80px] h-[25px] sm:w-[70px]">
                <SecondaryButton
                  text="URLコピー"
                  iconLeft="icon-copy"
                  fontSize="text-xxs"
                  onClick={openURLCopyModal}
                />
              </div>
              <div className="mr-1 w-[80px] h-[25px] sm:w-[70px]">
                <SecondaryButton
                  text="QRコード"
                  iconLeft="icon-qr"
                  fontSize="text-xxs"
                  onClick={toggleQRCodeModal}
                />
              </div>
              <div className="mr-1 w-[80px] h-[25px] sm:w-[70px]">
                <SecondaryButton
                  text="埋め込み"
                  iconLeft="icon-tag"
                  fontSize="text-xxs"
                  onClick={toggleWidgetModal}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <URLCopyModal
        isActive={isURLCopyModalActive}
        toggleModal={toggleURLCopyModal}
      />
      <QRCodeModal
        isActive={isQRCodeModalActive}
        toggleModal={toggleQRCodeModal}
        referralURL={referralURL}
      />
      <WidgetModal
        isActive={isWidgetModalActive}
        toggleModal={toggleWidgetModal}
        widgetURL={widgetURL}
      />
    </>
  );
};

export default Progress;
