import React from "react";
import Logo from "../../../images/logo.png";
import NoteLogo from "../../../images/logo_note.png";
import PRTIMESLogo from "../../../images/prtimes.jpg";
import SnsShareIcons from "../../components/Common/SnsShareIcons";
import BreadcrumbsSP from "../../components/Common/BreadCrumbsSP"

type BreadcrumbItem = {
  text: string,
  href?: string
}

type Props = {
  breadcrumbs?: Array<BreadcrumbItem>
};

const Footer: React.FC<Props> = (props) => {
  const { breadcrumbs } = props
  return (
    <div className="sm:px-5 lg:px-0">
      <div className="sm:py-[40px] flex flex-col items-center justify-between mx-auto py-0 max-w-main sm:flex-row">
        <div className="py-[30px] sm:border-[#D2DFE6] flex justify-center px-6 w-full sm:px-0 sm:border-r sm:border-solid">
          <div className="h-[42px] w-[210px] flex">
            <img src={PRTIMESLogo} alt="prtimes" />
          </div>
        </div>
        <div className="border-[#D2DFE6] flex justify-center py-5 w-full border-t border-solid sm:py-0 sm:border-none">
          <div className="block">
            <SnsShareIcons />
            <a className="flex items-center mt-3 hover:no-underline" href="https://note.com/team_spportunity/" target="_blank">
              <div className="w-[120px] h-[42px] inline-block">
                <img src={NoteLogo} alt="note" />
              </div>
              <p className="icon-external right-icon after:ml-[40px] flex mt-1 after:mt-1">
                でもスポチュニティ
              </p>
            </a>
          </div>
        </div>
        <BreadcrumbsSP items={breadcrumbs} />
      </div>
      <footer className="border-[#D2DFE6] pt-[40px] px-[30px] sm:pt-[70px] mx-auto max-w-footer border-t sm:flex-row sm:px-0 pb-[50px]">
        <div className="mx-auto max-w-main">
          <div className="flex flex-col justify-between sm:flex-row">
            <div className="w-[116px] h-[22px] mb-3 sm:mb-0">
              <a href="/" className="link-hover-none inline-block">
                <img src={Logo} alt="spportunity" />
              </a>
            </div>
            <section className="border-[#D2DFE6] sm:max-w-[140px] py-5 text-xs border-b sm:py-0 sm:border-none lg:max-w-none">
              <h2 className="mb-[15px] font-bold">プロジェクトをはじめる</h2>
              <ul>
                <li className="mt-3">
                  <a href="https://corp.spportunity.com/" className="icon-stick-arrow-right right-icon sm:after:content-none flex justify-between items-center sm:inline-block">スポチュニティとは</a>
                </li>
                <li className="mt-3 sm:mt-[5px]">
                  <a href="https://corp.spportunity.com/clubteam/" className="icon-stick-arrow-right right-icon sm:after:content-none flex justify-between items-center sm:inline-block">プロジェクトのはじめ方</a>
                </li>
                <li className="mt-3 sm:mt-[5px]">
                  <a href="https://corp.spportunity.com/reason/" className="icon-stick-arrow-right right-icon sm:after:content-none flex justify-between items-center sm:inline-block">スポチュニティが選ばれる理由</a>
                </li>
                <li className="mt-3 sm:mt-[5px]">
                  <a href="/simulator" className="icon-stick-arrow-right right-icon sm:after:content-none flex justify-between items-center sm:inline-block">支援金シミュレーション</a>
                </li>
                <li className="mt-3 sm:mt-[5px]">
                  <a href="https://management.spportunity.com/accounts/login" className="icon-stick-arrow-right right-icon sm:after:content-none flex justify-between items-center sm:inline-block">プロジェクトを作る</a>
                </li>
                {/* Phase2では非表示 */}
                {/* 
                  <li className="mt-3 sm:mt-[5px]">
                    <a href="" className="icon-stick-arrow-right right-icon sm:after:content-none flex justify-between items-center sm:inline-block">成功者インタビュー</a>
                  </li>
                */}
                {/* <li className="mt-3 sm:mt-[5px]">
                  <a href="https://corp.spportunity.com/spportunitycharity" className="icon-stick-arrow-right right-icon sm:after:content-none flex justify-between items-center sm:inline-block">支援募集プロジェクトのはじめ方</a>
                </li> */}
                {/*
                  <li className="mt-3 sm:mt-[5px]">
                    <a href="" className="icon-stick-arrow-right right-icon sm:after:content-none flex justify-between items-center sm:inline-block">スポチュニティのノウハウ</a>
                  </li>
                */}
                <li className="mt-3 sm:mt-[5px]">
                  <a href="https://corp.spportunity.com/spportunitycharity" className="icon-stick-arrow-right right-icon sm:after:content-none flex justify-between items-center sm:inline-block">寄付型クラウドファンディングとは</a>
                </li>
                <li className="mt-3 sm:mt-[5px]">
                  <a href="https://corp.spportunity.com/campaign/" className="icon-stick-arrow-right right-icon sm:after:content-none flex justify-between items-center sm:inline-block">紹介キャッシュバックプログラム</a>
                </li>
              </ul>
            </section>
            <section className="border-[#D2DFE6] sm:max-w-[140px] py-5 text-xs border-b sm:py-0 sm:border-none lg:max-w-none">
              <h2 className="mb-[15px] font-bold">プロジェクトをさがす</h2>
              <ul>
                <li>
                  <a href="/search/status" className="icon-stick-arrow-right right-icon sm:after:content-none flex justify-between items-center sm:inline-block">ステータスからさがす</a>
                </li>
                <li className="mt-3 sm:mt-[5px]">
                  <a href="/ranking" className="icon-stick-arrow-right right-icon sm:after:content-none flex justify-between items-center sm:inline-block">支援金ランキングからさがす</a>
                </li>
                <li className="mt-3 sm:mt-[5px]">
                  <a href="/search/team" className="icon-stick-arrow-right right-icon sm:after:content-none flex justify-between items-center sm:inline-block">クラブチームからさがす</a>
                </li>
                <li className="mt-3 sm:mt-[5px]">
                  <a href="/search" className="icon-stick-arrow-right right-icon sm:after:content-none flex justify-between items-center sm:inline-block">ゆかり検索/キーワード検索</a>
                </li>
              </ul>
            </section>
            <div className="sm:max-w-[140px] text-xs lg:max-w-none">
              <section className="border-[#D2DFE6] border-b sm:border-none py-5 sm:py-0">
                <h2 className="mb-[15px] font-bold">スポチュニティとは</h2>
                <ul>
                  <li>
                    <a href="https://corp.spportunity.com/vision_mission/" className="icon-stick-arrow-right right-icon sm:after:content-none flex justify-between items-center sm:inline-block">スポチュニティの理念</a>
                  </li>
                  <li className="mt-3 sm:mt-[5px]">
                    <a href="https://corp.spportunity.com/ambassador/" className="icon-stick-arrow-right right-icon sm:after:content-none flex justify-between items-center sm:inline-block">スポチュニティ・アンバサダー</a>
                  </li>
                  <li className="mt-3 sm:mt-[5px]">
                    <a href="https://corp.spportunity.com/users_voice/" className="icon-stick-arrow-right right-icon sm:after:content-none flex justify-between items-center sm:inline-block">利用者の"生の声"</a>
                  </li>
                  <li className="mt-3 sm:mt-[5px]">
                    <a href="https://corp.spportunity.com/original_return/" className="icon-stick-arrow-right right-icon sm:after:content-none flex justify-between items-center sm:inline-block">オリジナルリターン</a>
                  </li>
                </ul>
              </section>
              <ul className="sm:mt-7 border-[#D2DFE6] border-b sm:border-none py-5 sm:py-0">
                <li>
                  <a href="https://corp.spportunity.com/faq/" className="icon-stick-arrow-right right-icon sm:after:content-none flex justify-between items-center sm:inline-block">よくあるご質問</a>
                </li>
                <li className="mt-4 sm:mt-[15px]">
                  <a href="https://corp.spportunity.com/inquiry/" className="icon-stick-arrow-right right-icon sm:after:content-none flex justify-between items-center sm:inline-block">お問い合わせ</a>
                </li>
                {/* Phase2では非表示 */}
                {/*
                  <li className="mt-4 sm:mt-[15px]">
                    <a href="" className="icon-stick-arrow-right right-icon sm:after:content-none flex justify-between items-center sm:inline-block">公式SNS一覧</a>
                  </li>
                */}
              </ul>
            </div>
            <ul className="sm:max-w-[140px] py-5 text-xs sm:py-0 lg:max-w-none">
              <li>
                <a href="https://corp.spportunity.com/recruit/" className="icon-stick-arrow-right right-icon sm:after:content-none flex justify-between items-center sm:inline-block">採用情報</a>
              </li>
              <li className="mt-3 sm:mt-[15px]">
                <a href="https://corp.spportunity.com/internship/" className="icon-stick-arrow-right right-icon sm:after:content-none flex justify-between items-center sm:inline-block">インターンシップ生募集</a>
              </li>
              <li className="mt-3 sm:mt-[15px]">
                <a href="https://corp.spportunity.com/term_of_use/" className="icon-stick-arrow-right right-icon sm:after:content-none flex justify-between items-center sm:inline-block">支援者向け利用規約</a>
              </li>
              <li className="mt-3 sm:mt-[15px]">
                <a href="https://corp.spportunity.com/term_of_use_for_company/" className="icon-stick-arrow-right right-icon sm:after:content-none flex justify-between items-center sm:inline-block">チーム・団体向け利用規約</a>
              </li>
              <li className="mt-3 sm:mt-[15px]">
                <a href="https://corp.spportunity.com/transactions/" className="icon-stick-arrow-right right-icon sm:after:content-none flex justify-between items-center sm:inline-block">特定商取引に関する表示</a>
              </li>
              <li className="mt-3 sm:mt-[15px]">
                <a href="https://corp.spportunity.com/privacy_policy/" className="icon-stick-arrow-right right-icon sm:after:content-none flex justify-between items-center sm:inline-block">プライバシーポリシー</a>
              </li>
              <li className="mt-3 sm:mt-[15px]">
                <a href="https://corp.spportunity.com/company/" className="icon-stick-arrow-right right-icon sm:after:content-none flex justify-between items-center sm:inline-block">運営会社</a>
              </li>
            </ul>
          </div>
          <div className="mt-5 text-center sm:text-left">
            <small className="text-[#768084] font-xs">
              &copy; 2022 Spportunity. Inc. All Rights Reserved.
            </small>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
