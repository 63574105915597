import React from "react";
import BaseLinkButton from "./Buttons/BaseLinkButton";
import SecondaryLinkButtonWhite from "./Buttons/SecondaryLinkButtonWhite";
import ContactUsPC from "../../../images/contact_us_pc.png"
import ContactUsSP from "../../../images/contact_us_sp.png"

type Props = {};

const Contact: React.FC<Props> = (props) => {
  return (
    <div className="bg-[#DBE7ED] px-[30px] pb-[50px] pt-[60px] sm:pt-[110px] sm:pb-[90px] sm:text-center">
      <div className="mx-auto max-w-[853px] mb-[60px] hidden sm:block">
        <img src={ContactUsPC} alt="お問い合わせ" />
      </div>
      <div className="mx-auto mb-9 sm:hidden">
        <img src={ContactUsSP} alt="お問い合わせ" />
      </div>
      <div className="text-[#768084]">
        <p className="mb-4">まずはお話を聞かせてください。</p>
        <p>
          スポチュニティは、スポーツ特化型のクラウドファンディング。
          <br />
          どこよりも手厚く対応いたします。
        </p>
      </div>
      <div className="sm:mt-[50px] flex flex-col justify-center mt-8 sm:flex-row sm:items-center">
        <div className="sm:mr-[10px] mb-4 sm:mb-0 sm:w-[330px] h-[60px] w-full">
          <BaseLinkButton
            text="プロジェクトの相談をする"
            link="https://corp.spportunity.com/clubteam_entry"
          />
        </div>
        <div className="flex">
          <div className="mr-[10px] w-[50%] h-[50px] sm:w-[170px]">
            <SecondaryLinkButtonWhite
              text="利用申請する"
              link="https://management.spportunity.com/"
              iconRight="icon-arrow-blue"
              isExternal={true}
            />
          </div>
          <div className="w-[50%] h-[50px] sm:w-[170px]">
            <SecondaryLinkButtonWhite
              text="選ばれる理由"
              link="https://corp.spportunity.com/reason/"
              iconRight="icon-arrow-blue"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
