import React, { useState } from "react";
import { FieldPath, FieldValues, useController, UseControllerProps } from "react-hook-form";


type Props = {
  placeholder: string;
  id: string;
  isInitial?: boolean;
}

function TextArea<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
  >({ isInitial = true, ...props }: UseControllerProps<TFieldValues, TName> & Props) {
  const {
    field,
    formState
  } = useController(props);
  const [isFocused, setIsFocused] = useState<boolean>(isInitial)

  return (
    <>
      <textarea
         {...field}
        name={props.name}
        id={props.id}
        cols={30}
        rows={8}
        className={`${formState?.errors[props.name] && "!bg-[#FFE6E6] !border-[#FF0000] !border-2"} ${!isFocused || field.value ? "bg-white" : "bg-[#D3EAFF] border-[#006AC9] border-2"} border border-[#B1C2CC] w-full block px-[15px] py-[10px]`}
        placeholder={props.placeholder}
        onChange={(e) => {
          field.onChange(e);
        }}
        onFocus={() => setIsFocused(false)}
      />
      <div className="text-[#FF0000] mt-1 text-xs">
        {formState?.errors[props.name]?.type === "required" && (
          <p className="before:content-['*']">必須項目です</p>
        )}
      </div>
    </>
  );
}

export default TextArea;
