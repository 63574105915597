import React, { useCallback, useRef, useState } from "react";
import BaseLinkButton from "../../components/Common/Buttons/BaseLinkButton";
import SecondaryButton from "../../components/Common/Buttons/SecondaryButton";
import Pagination from "../../components/Common/Pagination";
import PastInvestmentCard from "../../components/Common/Cards/PastInvestmentCard";
import MarketImg from "../../../images/spportunity_market.png";
import ColumnImg from "../../../images/column_banner.jpg";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import SliderWrapper from "../../components/Common/SliderWrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";
import SecondaryLinkButton from "../../components/Common/Buttons/SecondaryLinkButton";
import Layout from "../../components/Common/Layout";
import { client } from "../../types/pages/Team/index";
import Map from "../../components/Team/Map"

type Props = {
  api_key: string;
  client: client
  email: string;
};

const Index: React.FC<Props> = (props) => {
  const containerStyleSP = {
    width: "100%",
    height: "42.6vw",
  };

  const containerStylePC = {
    width: "100%",
    height: "200px",
  };

  const center = {
    lat: Number(props.client.lat),
    lng: Number(props.client.lng),
  };

  const slider = {
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
  };

  return (
    <Layout
      breadcrumbs={[
        {
          text: "HOME",
          href: "/",
        },
        {
          text: props.client.client_name,
        },
      ]}
    >
      <section className="sm:max-w-main sm:mx-auto sm:mt-[60px] sm:mb-10">
        <div className="sm:flex px-[30px] md:px-0 sm:mb-[90px]">
          <div className="mb-[30px] sm:w-[380px] sm:mb-0 sm:mr-7 mx-[-30px] sm:mx-0">
            <img src={props.client.owner_image} alt="チーム名" />
          </div>
          <div className="mb-[60px] sm:w-[calc(100%-408px)]">
            <h2 className="mb-[30px] text-lg font-bold">{props.client.client_name}</h2>
            <p>{props.client.belonging}</p>
          </div>
        </div>
        {/* <section className="px-[30px] mb-[60px] md:px-0">
          <h2 className="mb-5 font-medium">最近の活動状況</h2>
          <ul className="mr-[-30px] flex overflow-x-auto lg:hidden">
            {[1, 2, 3, 4, 5, 6].map((i) => (
              <li className="mr-[15px] last:mr-[30px] sm:mr-[25px]" key={i}>
                <div className="w-[150px] sm:w-[180px]">
                  <div className="mb-[10px]">
                    <img src="https://placehold.jp/150x150.png" alt="" />
                  </div>
                  <div className="text-[#768084] text-[10px] mb-[10px] break-all">
                    NamenameNamenamenamenameNamenameNamename/1時間前
                  </div>
                  <p className="mb-[10px] text-sm font-bold">
                    いつも住田選手を応援いただきありがとうございます。みなさま、ついに明日広島ホームテレビ(5ch)・5up内で住田選手の特集が明日放送予定です。TV欄にも登場しました...
                  </p>
                  <div className="text-[#768084] text-[10px] flex">
                    <div className="border-[#D2DFE6] pr-2 leading-none border-r">
                      コメント100
                    </div>
                    <div className="icon-heart left-icon flex items-center pl-2 underline leading-none">
                      100
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          <ul className="hidden lg:block slider-col-5">
            <SliderWrapper>
              <Slider {...cardCol5}>
                {[1, 2, 3, 4, 5, 6].map((i) => (
                  <li className="" key={i}>
                    <div className="w-[180px]">
                      <div className="mb-[10px]">
                        <img src="https://placehold.jp/150x150.png" alt="" />
                      </div>
                      <div className="text-[#768084] text-[10px] mb-[10px] break-all">
                        NamenameNamenamenamenameNamenameNamename/1時間前
                      </div>
                      <p className="mb-[10px] text-sm font-bold">
                        いつも住田選手を応援いただきありがとうございます。みなさま、ついに明日広島ホームテレビ(5ch)・5up内で住田選手の特集が明日放送予定です。TV欄にも登場しました...
                      </p>
                      <div className="text-[#768084] text-[10px] flex">
                        <div className="border-[#D2DFE6] pr-2 leading-none border-r">
                          コメント100
                        </div>
                        <div className="icon-heart left-icon flex items-center pl-2 underline leading-none">
                          100
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </Slider>
            </SliderWrapper>
          </ul>
        </section> */}
        <section className="px-[30px] mb-[60px] md:px-0">
          <h3 className="mb-5 font-medium sm:mb-10">活動拠点</h3>
          <div className="mb-[10px] sm:hidden">
            <Map
              apiKey={props.api_key}
              center={center}
              containerStyle={containerStyleSP}
            />
          </div>
          <div className="mb-[10px] sm:block hidden">
            <Map
              apiKey={props.api_key}
              center={center}
              containerStyle={containerStylePC}
            />
          </div>
          <div className="left-icon icon-location flex items-center text-sm">
            {props.client.full_address}
          </div>
        </section>
        <div className="px-[30px] bg-[#F4F6F8] mt-[60px] mb-[30px] sm:px-0">
          <div className="py-[30px] sm:flex sm:justify-between sm:py-[60px] sm:w-full sm:max-w-main">
            <div className="md:w-[calc(100%-450px)] sm:w-[calc(100%-430px)] sm:border-[#DEDEDE] sm:flex sm:justify-center sm:px-5 sm:border-r">
              <div className="sm:mr-5 icon-external right-icon after:absolute relative after:right-0 after:top-1 mb-3 sm:after:top-auto sm:after:right-auto">
                <div className="text-[#768084] text-xs">
                  スポーツの熱い思いを届けるメディア
                </div>
                <a className="text-lg" href="https://media.spportunity.com/" target="_blank" rel="noopener noreferrer">
                  スポチュニティコラムにて<br className="hidden md:block"/>掲載中
                </a>
              </div>
              <div className="h-[32vw] sm:max-w-[260px] sm:max-h-[100px] mb-6 w-full sm:mb-0">
                <a href="https://media.spportunity.com/" target="_blank" rel="noopener noreferrer" className="block overflow-hidden link-hover-none">
                  <img
                    src={ColumnImg}
                    alt="スポチュニティコラム"
                    className="object-cover img-expansion"
                  />
                </a>
              </div>
            </div>
            <div className="md:w-[450px] sm:flex sm:flex-col sm:justify-center md:pl-[30px] px-[10px] sm:w-[430px]">
              <div className="flex mb-9 sm:mb-5">
                <div className="hidden sm:block md:w-full mb-[10px] sm:mr-[10px] sm:mb-0 h-[50px] sm:w-[200px]">
                  <SecondaryLinkButton
                    text={`最新プロジェクトの問い合わせ先\nメールアドレス`}
                    fontSize="text-xs"
                    link={`mailto:${props.email}`}
                  />
                </div>
                <div className="w-[50%] mr-2 sm:w-full sm:hidden sm:mr-0 sm:mb-2 h-[50px] sm:w-[200px]">
                  <SecondaryLinkButton
                    text={`最新プロジェクトの\n問い合わせ先\nメールアドレス`}
                    fontSize="text-xs"
                    link={`mailto:${props.email}`}
                  />
                </div>
                <div className="w-[50%] sm:w-full sm:mr-0 sm:mb-2 h-[50px]">
                  <SecondaryLinkButton
                    text="公式Webサイト"
                    fontSize="text-sm"
                    link={props.client.homepage_url}
                    disabled={props.client.homepage_url === null}
                  />
                </div>
              </div>
              <ul className="flex justify-center">
                <li className="mr-10 sm:mr-[30px]">
                  <a href={props.client.facebook_account} className="link-hover-none">
                    <FontAwesomeIcon icon={faFacebook} size="2x" color={props.client.facebook_account ? "#1F2122" : "#D2DFE5"} />
                  </a>
                </li>
                <li className="mr-10 sm:mr-[30px]">
                  <a
                    href={props.client.twitter_account && `https://twitter.com/${props.client.twitter_account}?ref_src=twsrc%5Etfw`}
                    className="link-hover-none"
                  >
                    <FontAwesomeIcon icon={faTwitter} size="2x" color={props.client.twitter_account ? "#1F2122" : "#D2DFE5"} />
                  </a>
                </li>
                <li className="mr-10 sm:mr-[30px]">
                  <a href={props.client.instagram_account} className="link-hover-none">
                    <FontAwesomeIcon icon={faInstagram} size="2x" color={props.client.instagram_account ? "#1F2122" : "#D2DFE5"} />
                  </a>
                </li>
                <li>
                  <a href={props.client.youtube_account} className="link-hover-none">
                    <FontAwesomeIcon icon={faYoutube} size="2x" color={props.client.youtube_account ? "#1F2122" : "#D2DFE5"} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        { props.client.investment_to_donate_to_id && (
          <div className="px-[30px] mb-[30px] sm:px-0 sm:mb-10">
            <section className="bg-[#F4F6F8] p-[30px]">
              <div className="sm:w-[700px] sm:mx-auto">
                <h3 className="mb-[10px]">継続支援</h3>
                <div className="sm:flex sm:justify-between">
                  <p className="text-[#FF0000] mb-5 sm:w-[400px]">
                    月額課金で継続的にチームへ寄付することができます。リターンはございませんのでご注意ください。
                  </p>
                  <div className="sm:w-[255px] h-[50px] sm:mx-auto">
                    <BaseLinkButton
                      text="継続的に寄付をする"
                      link={`/clients/${props.client.id}/monthly_donations/new`}
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
        {/* <div className="bg-[#F4F6F8] sm:w-screen sm:mx-[calc(50%-50vw)]">
          <section className="pt-[30px] pb-[60px] sm:max-w-main sm:mx-auto sm:pt-10 sm:pb-[80px]">
            <div className="border-[#B1C2CC] mx-[30px] pb-5 text-center border-b sm:flex sm:justify-center sm:items-center md:mx-0 sm:pb-8">
              <div className="flex flex-col-reverse sm:flex-row sm:items-center">
                <p className="text-[#768084] text-xs">クラブ応援グッズを販売中!!</p>
                <div className="w-[72px] h-[32px] mb-[10px] mx-auto sm:mx-5">
                  <img src={MarketImg} alt="スポチュニティマーケット" />
                </div>
              </div>
              <h3 className="text-[17px]">スポチュニティマーケット</h3>
            </div>
            <div className="px-[30px] md:px-0">
              <div className="mb-[45px] mt-[10px] flex justify-between text-xs">
                <div>NNN件</div>
                <div>
                  並べ替え：
                  <select name="" id="" className="bg-[#F4F6F8]">
                    <option value="">公開中のプロジェクト</option>
                  </select>
                </div>
              </div>
              <div>
                <ul className="flex flex-wrap justify-between sm:grid-cols-4 sm:grid md:gap-x-6 md:grid-cols-5 sm:gap-x-5 mb-[60px]">
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => (
                    <li className="w-[47.6%] mb-[40px] sm:w-auto" key={i}>
                      <article>
                        <div className="mb-[10px]">
                          <img src="https://placehold.jp/150x113.png" alt="" />
                        </div>
                        <h4 className="mb-2 text-sm font-bold">
                          オリジナルフォトブック（チアリーダー直筆メッセージ入り）
                        </h4>
                        <div className="text-center">
                          <div className="text-[#768084] line-through text-sm font-bold flex justify-center items-center">
                            123,456,000
                            <span className="text-[10px]">円（税込）</span>
                          </div>
                          <div className="inline-block">
                            <div className="bg-[#006AC9] w-[50px] mx-auto text-white text-xs font-bold leading-none">
                              50<span className="text-[10px]">%OFF</span>
                            </div>
                            <div className="down-triangle"></div>
                          </div>
                          <div className="font-bold">
                            123,456,000
                            <span className="text-[10px]">円（税込）</span>
                          </div>
                        </div>
                        <div className="h-[40px]">
                          <BaseLinkButton
                            text="購入する"
                            link=""
                            fontSize="text-xs"
                          />
                        </div>
                      </article>
                    </li>
                  ))}
                </ul>
                <Pagination />
              </div>
            </div>
          </section>
        </div> */}
        {props.client.investments.length > 0 &&
          <section className="py-[60px] px-[30px] lg:px-0">
            <h3 className="mb-[30px] font-medium">過去の支援募集プロジェクト</h3>
            <ul className="mr-[-30px] flex overflow-x-auto lg:hidden hidden-scrollbar">
              {props.client.investments.map((item, i) => (
                <li key={i} className="min-w-[150px] mr-[15px] last:mr-[30px]">
                  <PastInvestmentCard
                    title={item.title}
                    supportMoney={item.total_amount}
                    supporterCount={item.supporter_count}
                    headingElement={4}
                    imgUrl={item.main_image}
                    link={`/${props.client.prefecture}/team/${props.client.id}/invest/${item.id}/detail`}
                    targetAmount={item.target_amount}
                  />
                </li>
              ))}
            </ul>
            <ul className="hidden lg:block slider-col-4">
              <SliderWrapper col={4}>
                {props.client.investments.map((item, i) => (
                  <li key={i} className="w-[220px] mr-[15px] last:mr-[30px]">
                    <PastInvestmentCard
                      title={item.title}
                      supportMoney={item.total_amount}
                      supporterCount={item.supporter_count}
                      headingElement={4}
                      imgUrl={item.main_image}
                      link={`/${props.client.prefecture}/team/${props.client.id}/invest/${item.id}/detail`}
                      targetAmount={item.target_amount}
                    />
                  </li>
                ))}
              </SliderWrapper>
            </ul>
          </section>
        }
        {props.client.players.length > 0 &&
          <section className="px-[30px] lg:px-0">
            <h3 className="mb-[30px] font-medium">選手・スタッフ</h3>
            <ul className="mr-[-30px] flex overflow-x-auto lg:hidden hidden-scrollbar">
              {props.client.players.map((item, i) => (
                <li
                  key={i}
                  className="min-w-[150px] mr-[15px] last:mr-[30px] inline-block"
                >
                  <article className="w-full">
                    <div className="mb-[10px]">
                      <a href={item.player_path} className="link-hover-none">
                        <img src={item.player_image} alt={item.player_name} />
                      </a>
                    </div>
                    <h4 className="mb-[10px] text-sm font-bold">
                      <a href={item.player_path}>{item.player_name}</a>
                    </h4>
                    <p className="text-[#768084] mb-[10px] text-xs">{item.graduates}</p>
                  </article>
                </li>
              ))}
            </ul>
            <ul className="hidden lg:block slider-col-6">
              <SliderWrapper col={6}>
                {props.client.players.map((item, i) => (
                  <li
                    key={i}
                    className="mr-[15px] inline-block w-[140px]"
                  >
                    <article className="w-full">
                      <div className="mb-[10px]">
                        <a href={item.player_path} className="link-hover-none">
                          <img src={item.player_image} alt={item.player_name} />
                        </a>
                      </div>
                      <h4 className="mb-[10px] text-sm font-bold">
                        <a href={item.player_path}>{item.player_name}</a>
                      </h4>
                      <p className="text-[#768084] mb-[10px] text-xs">{item.graduates}</p>
                    </article>
                  </li>
                ))}
              </SliderWrapper>
            </ul>
          </section>
        }
      </section>
    </Layout>
  );
};

export default Index;
