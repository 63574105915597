import React, { useEffect, useMemo, useRef, useState } from "react";
import Comment from "../../components/Team/Comment";
import Progress from "../../components/Team/Progress";
import {
  client,
  investment,
  questionnaireAnswer,
} from "../../types/pages/Team/detail";
import SecondaryLinkButton from "../../components/Common/Buttons/SecondaryLinkButton";
import Introduction from "../../components/Team/Introduction";
import { useToggle } from "../../hooks/Common/useToggle";
import Community from "../../components/Team/Community";
import Tweet from "../../components/Team/Tweet";
import Questionnaire from "../../components/Team/Questionnaire";
import SliderWrapper from "../../components/Common/SliderWrapper";
import PastInvestmentCard from "../../components/Common/Cards/PastInvestmentCard";
import Layout from "../../components/Common/Layout";
import SecondaryButton from "../../components/Common/Buttons/SecondaryButton";
import { useProductVisibilityToggle } from "../../hooks/Team/useProductVisibilityToggle";
import EcReturnCard from "../../components/Common/Cards/EcReturnCard"
import AttentionEcReturnCard from "../../components/Common/Cards/AttentionEcReturnCard";
import { useFetchCommunityMessage } from "../../hooks/Team/useFetchCommunityMessage";
import "../../../stylesheets/project-detail.scss"
import { faFacebook, faInstagram, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { destination } from "../../types/pages/Team/detail";
import BaseButton from "../../components/Common/Buttons/BaseButton";
import BaseLinkButton from "../../components/Common/Buttons/BaseLinkButton";
import { IsProjectActive, projectStatusToString } from "../../utils/constants";
import { useTabRef } from "../../hooks/Team/useTabRef";
import ProjectDetailHeaderMenu from "../../components/Team/ProjectDetailHeaderMenu";

type Props = {
  client: client;
  investment: investment;
  team_image: string;
  prefecture: string;
  main_image: string;
  is_project_active: boolean;
  investment_progressing_sum: number;
  remaining_period: number;
  begin_date_time: string;
  end_date_time: string;
  begin_date: string;
  end_date: string;
  payment_due_date: string;
  questionnaire_answer: questionnaireAnswer;
  referral_url: string;
  widget_url: string;
  project_status: number;
  can_post_article: boolean;
  articles_length: number;
  your_paid_status_sum: number;
  your_monthly_courses: destination[];
  your_paid_status_investment_count: number;
  from?: string;
  is_draft?: boolean;
  total_amount?: number;
  supporter_count?: number;
  support_count?: number;
  draft_id?: number;
  draft_path?: string;
  can_tip: boolean;
  card_registered: boolean;
  is_logged_in: boolean;
  current_user_client_id: number | null;
  payJpPublicKey: string;
};

const Detail: React.FC<Props> = (props) => {
  const [isLargeDisplay, setIsLargeDisplay] = useState<boolean>(true);
  const [checkedItemIds, setCheckedItemIds] = useState([]);
  const { commentList } = useFetchCommunityMessage(props.client.id, props.investment.id);
  const returnAreaRef = useRef(null);
  const { isActive: isOpenOverviewAccordion, toggle: toggleOverviewAccordion } =
    useToggle();
  const {
    isActive: isOpenCommunityAccordion,
    toggle: toggleCommunityAccordion,
  } = useToggle();
  const {
    isActive: isOpenReferralAccordion,
    toggle: toggleReferralAccordion,
  } = useToggle();
  const { isActive: isOpenTwitterAccordion, toggle: toggleTwitterAccordion } =
    useToggle();
  const {
    isActive: isOpenQuestionnaireAccordion,
    toggle: toggleQuestionnaireAccordion,
  } = useToggle();

  const productsArray = props.investment?.ec_returns
  const { showReadMoreBtn, showAllProducts, productListRef } = useProductVisibilityToggle(productsArray?.length)

  const toggleReturnDisplay = (e) => {
    if (e.target.classList.value.includes("opacity-40")) {
      setIsLargeDisplay(!isLargeDisplay);
    }
  };

  const changeTab = (e) => {
    const tabArea = document.querySelector(".project-detail-tab-area");
    tabArea.scrollIntoView({ behavior: "smooth" });
    const tabContent = document.querySelectorAll(".tab-content");
    const tabItems: NodeListOf<HTMLSpanElement> = document.querySelectorAll(
      ".project-detail-tab .tab"
    );
    const sideMenuitems: NodeListOf<HTMLSpanElement> =
      document.querySelectorAll(".project-detail-side-menu .item");

    for (let i = 0; i < e.target.parentNode.childElementCount; i++) {
      if (e.target.parentNode.children[i] === e.target) {
        tabItems[i].classList.add("active");
        sideMenuitems[i].classList.add("active");
        tabContent[i].classList.remove("hidden");
      } else {
        tabItems[i].classList.remove("active");
        sideMenuitems[i].classList.remove("active");
        tabContent[i].classList.add("hidden");
      }
    }
  };

  const moveToReturnArea = () => {
    if (returnAreaRef.current) {
      returnAreaRef.current.scrollIntoView({behavior: "smooth"})
    }
  }

  useEffect(() => {
    window.addEventListener("resize", (e: UIEvent) => {
      const w = e.target as Window; 
      if(w.innerWidth >= 1000) {
        setIsLargeDisplay(true)
      }
    })
  }, [])

  const communityTab = useTabRef();
  const overviewTab = useTabRef();
  const sleep = (waitTime) => new Promise( resolve => setTimeout(resolve, waitTime) );

  useEffect(() => {
    window.onload = async () => {
      if (window.location.hash === "#community") {
        await sleep(200);
        moveToCommunityTab()
      }
    }
  }, [])

  const projectStatus = useMemo(() => {
    return projectStatusToString(props.project_status)
  }, [props.project_status])

  const hasPaid: boolean = props.your_paid_status_sum > 0;
  const hasMonthlyCourses: boolean = props.your_monthly_courses.length > 0;

  const moveToCommunityTab = () => {
    communityTab.moveToTab();
  }

  const moveToOverviewTab = () => {
    overviewTab.moveToTab();
  }

  const scrollToTarget = (elementId: string) => {
    const targetElement = document.getElementById(elementId);
    if (targetElement) {
      const headerHeight = 90
      const elementRect = targetElement.getBoundingClientRect();
      const scrollY = window.scrollY;
      const scrollToY = elementRect.top + scrollY - headerHeight;
      window.scrollTo({ top: scrollToY, behavior: 'smooth' });
    }
  };

  const handleCheckboxChange = (checked, itemId) => {
    if (checked) {
      setCheckedItemIds([...checkedItemIds, itemId]);
    } else {
      setCheckedItemIds(checkedItemIds.filter(id => id !== itemId));
    }
  };
  
  const createQueryStringForBulkShipping = (ids) => {
    return ids.map((id) => `ec_returns[]=${id}`).join('&');
  };

  const getBulkShippingLink = () => {
    const queryString = createQueryStringForBulkShipping(checkedItemIds);
    return props.is_draft ?
    `/${props.draft_path}` :
    `/invest/${props.investment.id}/ec_returns/web_settlements/new?` + queryString;
  };
  
  return (
    <div className="project-detail">
      <ProjectDetailHeaderMenu
        showReferralMenu={props.investment.referral_incentive_description}
        showPostMenu={props.client.twitter_account}
        showQuestionnaireMenu={props.investment.questionnaire.title}
        scrollToTarget={scrollToTarget}
      />
      <Layout
        breadcrumbs={[
          {
            text: "HOME",
            href: "/",
          },
          {
            text: props.investment.title,
          },
        ]}
      >
      <section>
        {props.project_status === 5 ?
          <div className="bg-[#006AC9] py-[10px] fixed w-full px-5 z-30 bottom-0">
            <div className="sm:w-[900px] sm:mx-auto flex items-center">
              <div className="h-[50px] aspect-[4/3] sm:h-[60px]">
                <img
                  src={props.team_image}
                  alt={props.client.client_name}
                  className="object-contain"
                />
              </div>
              <div className="text-white ml-[10px]">
                <p className="mb-1 text-sm line-clamp-1">{props.client.client_name}</p>
                <a
                  className="link-hover-none rounded-default border border-white !py-1 px-5 block text-xs text-center"
                  href={`/${props.prefecture}/team/${props.client.id}`}
                >
                  過去の支援募集プロジェクトはこちら
                </a>
              </div>
            </div>
          </div>
          :
          <>
            {checkedItemIds.length > 0 ? (
              <button
              className="md:hidden block bg-[#1F2122] text-white text-center block fixed z-30 h-[50px] w-full bottom-0"
              onClick={() => window.location.href = getBulkShippingLink()}
              >
                <span className="text-lg py-3 flex items-center justify-center whitespace-pre-line">
                  おまとめ支援に進む
                </span>
              </button>
            ) : (
              <button
                className="md:hidden block bg-[#1F2122] text-white text-center block fixed z-30 h-[50px] w-full bottom-0"
                onClick={moveToReturnArea}
              >
                <span className="text-lg py-3 flex items-center justify-center right-icon icon-arrow-top-2">リターン一覧</span>
              </button>
            )}
            <div className="w-[200px] h-[50px] fixed bottom-[30px] right-[max(30px,calc((100%-1280px)/2+80px))] z-30 hidden md:block">
              {checkedItemIds.length > 0 ? (
                <BaseLinkButton
                  text={"おまとめ支援に進む"}
                  fontSize="text-sm"
                  link={getBulkShippingLink()}
                />
              ) : (
                <BaseButton
                  text={"リターン一覧"}
                  iconRight={"icon-arrow-top-2"}
                  onClick={moveToReturnArea}
                />
              )}
            </div>
          </>
        }
        <div className="sm:hidden mb-10 relative">
          <div className="w-full">
            {props.investment.is_using_youtube_video ?
              <iframe style={{ width: '100%', aspectRatio: '4/3' }} src={props.investment.youtube_video_url} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
              :
              props.main_image ? (
                <img
                  src={props.main_image}
                  alt={props.investment.title}
                  className="object-cover"
                />
              ) : (
                <img
                  src="https://placehold.jp/375x280.png"
                  alt={props.investment.title}
                  className="object-cover"
                />
              )
            }
          </div>
          {props.investment.next_target_amount &&
            <p
              className="bg-black text-white text-sm absolute p-2 top-4 text-center font-raleway"
              style={{ boxShadow: "5px 5px 2px 0 rgba(0, 0, 0, .7)" }}
            >
              NEXT GOAL<br />挑戦中
            </p>
          }
          <div className="px-[30px] mb-10">
            <div className="py-[30px]">
              <div className="inline-block bg-[#1F2122] px-5 h-[20px] leading-[20px] mb-[10px] font-raleway text-center text-white text-xs">
                {props.is_draft ? "preview" : projectStatus}
              </div>
              <h2 className="text-lg">{props.investment.title}</h2>
            </div>
            <Progress
              investmentID={props.is_draft ? 0 : props.investment.id}
              investmentProgressingSum={props.is_draft ? props.total_amount : props.investment.total_amount}
              pendingAmount={props.investment.pending_amount}
              targetAmount={props.investment.target_amount}
              nextTargetAmount={props.investment.next_target_amount}
              supportCount={props.is_draft ? props.support_count : props.investment.support_count}
              supporterCount={props.is_draft ? props.supporter_count : props.investment.supporter_count}
              isProjectActive={props.is_project_active}
              remainingPeriod={props.remaining_period}
              beginDateTime={props.begin_date_time}
              endDateTime={props.end_date_time}
              beginDate={props.begin_date}
              endDate={props.end_date}
              paymentDueDate={props.payment_due_date}
              referralURL={props.referral_url}
              widgetURL={props.widget_url}
              investmentTitle={props.investment.title}
              projectStatusCode={props.project_status}
              canTip={!props.is_draft && props.can_tip}
              cardRegistered={props.card_registered}
              isLoggedIn={props.is_logged_in}
              payJpPublicKey={props.payJpPublicKey}
            />
          </div>
        </div>
        <div className="max-w-main hidden mx-auto sm:block mb-[60px]">
          <div className="py-[70px] px-[30px] md:px-0">
            <div className="inline-block bg-[#1F2122] px-5 h-[20px] leading-[20px] mb-[10px] font-raleway text-center text-white text-xs">
              {props.is_draft ? "preview" : projectStatus}
            </div>
            <h2 className="text-2xl">{props.investment.title}</h2>
          </div>
          <div className="px-[30px] flex items-center justify-between md:px-0">
            <div className="relative">
              <div className="h-[calc((100vw-60px-360px-60px)*0.75)] w-[calc(100vw-60px-360px-60px)] md:w-[580px] md:h-[435px]">
                {props.investment.is_using_youtube_video ?
                  <iframe style={{ width: '100%', aspectRatio: '4/3' }} src={props.investment.youtube_video_url} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                  :
                  props.main_image ? (
                    <img
                      src={props.main_image}
                      alt={props.investment.title}
                      className="object-cover"
                    />
                  ) : (
                    <img
                      src="https://placehold.jp/580x435.png"
                      alt={props.investment.title}
                      className="object-cover"
                    />
                  )
                }
              </div>
              {props.investment.next_target_amount &&
                <p
                  className="font-raleway absolute bg-black text-white p-2 top-4 text-center text-sm"
                  style={{ boxShadow: "5px 5px 2px 0 rgba(0, 0, 0, .7)" }}
                >
                  NEXT GOAL<br />挑戦中
                </p>
              }
            </div>
            <div>
              <Progress
                investmentID={props.is_draft ? 0 : props.investment.id}
                investmentProgressingSum={props.is_draft ? props.total_amount : props.investment.total_amount}
                targetAmount={props.investment.target_amount}
                pendingAmount={props.investment.pending_amount}
                nextTargetAmount={props.investment.next_target_amount}
                supportCount={props.is_draft ? props.support_count : props.investment.support_count}
                supporterCount={props.is_draft ? props.supporter_count : props.investment.supporter_count}
                isProjectActive={props.is_project_active}
                remainingPeriod={props.remaining_period}
                beginDateTime={props.begin_date_time}
                endDateTime={props.end_date_time}
                beginDate={props.begin_date}
                endDate={props.end_date}
                paymentDueDate={props.payment_due_date}
                referralURL={props.referral_url}
                widgetURL={props.widget_url}
                investmentTitle={props.investment.title}
                projectStatusCode={props.project_status}
                canTip={!props.is_draft && props.can_tip}
                cardRegistered={props.card_registered}
                isLoggedIn={props.is_logged_in}
                payJpPublicKey={props.payJpPublicKey}
              />
            </div>
          </div>
        </div>
        {(hasPaid || hasMonthlyCourses) && (
          <section className="px-[30px] mb-10 md:max-w-main md:mx-auto md:px-0">
            <h3 className="text-sm mb-[15px] font-bold">あなたのこのプロジェクトの支援状況</h3>
            <div className="sm:flex">
              <div className="hidden sm:block">
                <p className="text-[#768084] mb-[10px]">支援数 {props.your_paid_status_investment_count}件</p>
                <dl className="sm:pr-[30px] flex text-xs items-center sm:w-[400px] justify-between">
                  <dt className="text-[#768084] sm:text-2xl">支援総額</dt>
                  <dd className="sm:text-4xl">{props.your_paid_status_sum.toLocaleString()}円</dd>
                </dl>
              </div>
              <div className="sm:hidden mb-5">
                <p className="text-[#768084] text-xs mb-[10px]">支援総額 | 支援数 {props.your_paid_status_investment_count}件</p>
                <div className="text-xs">{props.your_paid_status_sum.toLocaleString()}円</div>
              </div>
                <dl className="mt-5 sm:border-l sm:border-[#D2DFE6] sm:mt-0 sm:pl-[30px] sm:w-[calc(100%-300px)]">
                  <dt className="text-[#768084] mb-[10px] text-xs sm:text-base">支援コース(月額)</dt>
                    {
                      hasMonthlyCourses ?
                      <dd>
                        <ul className="sm:flex sm:flex-wrap text-xs">
                          {props.your_monthly_courses.map((item, i) => (
                            <li key={i} className="sm:w-[48%] mt-[10px]">
                              <span>
                                <a href={`/mypage/support_history/payment_details?investment_id=${item.investment_id}#${item.destination_detail_id}`} className="link-hover-none underline mr-[10px]">
                                  {item.plan_name}
                                </a>
                                {item.investment_value.toLocaleString()}円
                              </span>
                            </li>
                          ))}
                        </ul>
                      </dd>
                        :
                      <p className="text-xs">支援コース(月額)はありません。</p>
                    }
                </dl>
            </div>
          </section>
        )}
        {props.investment.ec_return_attentions.length > 0 && (
          <div className="bg-[#D2DFE6] mb-10">
            <div className="py-8 flex max-w-main mx-auto overflow-x-auto pl-[30px] md:pl-0 hidden-scrollbar">
            {props.investment.ec_return_attentions.map((item, i) =>
              item.published && (
                <AttentionEcReturnCard
                  key={i}
                  name={item.name}
                  imgPath={item.ec_return_images[0]?.path}
                  label={item.label}
                  link={
                    props.is_draft ?
                      `/${props.draft_path}/${props.prefecture}/${props.client.id}/${props.draft_id}/${item.id}${window.location.search}`
                      :
                      `/${props.prefecture}/team/${props.client.id}/invest/${props.investment.id}/detail/${item.id}/ec_return` + (props.from ? `?from=${props.from}` : "")
                  }
                  headingElement={3}
                />
              )
            )}
            </div>
          </div>
        )}
        {commentList?.length > 0 && (
          <Comment
            commentList={commentList}
            moveToCommunityTab={moveToCommunityTab}
            moveToCommunitySPTab={() => scrollToTarget("community")}
          />
        )}
        {/* phase2では実装しないのでコメントアウト
        <section className="bg-[#F4F6F8] max-w-main mx-auto hidden sm:block mb-10">
          <a href="" className="link-hover-none block icon-stick-arrow-right right-icon !py-8 relative flex justify-center after:absolute after:right-6 items-center">
            <div className="flex justify-center">
              <div className="w-[132px] h-[62px] mr-8">
                <img src={MarketImg} alt="スポチュニティマーケット" />
              </div>
              <div>
                <p className="text-[#B1C2CC] font-raleway text-sm">Spportunity MARKET</p>
                <h2 className="text-2xl">スポチュニティマーケット</h2>
                <p className="text-[#768084] text-xs">クラブ応援グッズを販売中！！</p>
              </div>
            </div>
          </a>
        </section> */}
        <section className="mt-[60px] sm:mt-[100px] sm:mx-0 px-[30px] sm:px-0">
          <h3 className="before:left-[-30px] before:top-[9px] text-[15px] before:content-[''] before:w-[25px] before:h-[1px] before:bg-[#B1C2CC] sm:pl-[50px] sm:before:left-0 sm:w-[700px] sm:before:w-[50px] before:absolute relative before:inline-block mb-10 sm:mx-auto">
            このプロジェクトについて
          </h3>
          <div className="sm:w-[700px] sm:mx-auto">
            <h4 className="mb-10 text-lg font-bold sm:text-xl">
              {props.investment.sub_title}
            </h4>
            <div className="mb-10">
              {props.investment.abstract.split("\n").map((item: string, index: number) => (
                <p className="min-h-[1.25rem]" key={index}>{item}</p>
              ))}
            </div>
            <div className="sm:hidden mx-auto w-[150px] h-[40px] mt-6">
              <SecondaryButton
                text="Read more"
                iconRight="icon-arrow-under-small"
                fontRaleway={true}
                onClick={() => scrollToTarget("overview")}
              />
            </div>
            <div className="hidden sm:block mx-auto w-[220px] h-[40px] mt-6">
              <SecondaryButton
                text="Read more"
                iconRight="icon-arrow-under-small"
                fontRaleway={true}
                onClick={moveToOverviewTab}
              />
            </div>
          </div>
        </section>
        {productsArray.length > 0 && (
          <section className="max-w-main mx-auto px-[30px] md:px-0 mb-[100px] pt-[100px]" ref={returnAreaRef}>
            <div className="flex justify-between items-center mb-10">
              <h3 className="font-medium">リターン</h3>
              <div className="flex md:hidden">
                <div
                  className={`left-icon icon-return-list flex items-center mr-4 text-xs hover:cursor-pointer ${
                    isLargeDisplay ? "opacity-40" : ""
                  } `}
                  onClick={(e) => toggleReturnDisplay(e)}
                >
                  リスト
                </div>
                <div
                  className={`left-icon icon-return-large flex items-center text-xs hover:cursor-pointer ${
                    isLargeDisplay ? "" : "opacity-40"
                  }`}
                  onClick={(e) => toggleReturnDisplay(e)}
                >
                  大きく
                </div>
              </div>
            </div>
            <div className={isLargeDisplay ? "flex flex-wrap md:justify-start" : "hidden"} ref={productListRef}>
              {productsArray.map((item, i: number) => (
                <div className="md:w-[220px] min-w-[150px] md:mr-10 md:[&:nth-child(4n)]:mr-0 md:mb-[60px] mb-10 w-[calc((100vw-75px)/2)] [&:nth-child(2n)]:ml-[15px] md:!ml-0" key={i}>
                  <EcReturnCard
                    link={
                      props.is_draft ?
                        `/${props.draft_path}/${props.prefecture}/${props.client.id}/${props.draft_id}/${item.id}${window.location.search}`
                        :
                        `/${props.prefecture}/team/${props.client.id}/invest/${props.investment.id}/detail/${item.id}/ec_return` + (props.from ? `?from=${props.from}` : "")
                    }
                    id={item.id}
                    name={item.name}
                    category={item.label}
                    headingElement={4}
                    imgUrl={item.ec_return_images[0]?.path}
                    price={item.price}
                    supportCount={item.support_count}
                    stockQuantity={item.stock_quantity}
                    isNew={item.is_new_arrival}
                    isSoldOut={item.is_sold_out}
                    // プロジェクトステータスが「公開中」と「NEXT GOAL挑戦中」のときのみおまとめ支援ができる
                    showCheckbox={IsProjectActive(props.project_status) && !item.is_subscription && !item.for_corporation && !item.is_sold_out}
                    onCheckboxChange={(checked) => handleCheckboxChange(checked, item.id)}
                    isChecked={checkedItemIds.includes(item.id)}
                    disabled={!checkedItemIds.includes(item.id) && checkedItemIds.length >= 5}
                  />
                </div>
              ))}
            </div>
            <div className={isLargeDisplay ? "hidden" : "block"}>
              {productsArray.map((item, i: number) => {
                const isChecked = checkedItemIds.includes(item.id);
                return (
                  <div key={i} className="mb-[25px]">
                    <>
                      <label className="flex items-center pb-2 h-10 cursor-pointer">
                        {IsProjectActive(props.project_status) && !item.is_subscription && !item.for_corporation && !item.is_sold_out && (
                          <input
                            type="checkbox"
                            className="w-4 h-4 mr-2"
                            checked={isChecked}
                            onChange={(e) => handleCheckboxChange(e.target.checked, item.id)}
                            disabled={!checkedItemIds.includes(item.id) && checkedItemIds.length >= 5}
                          />
                        )}
                        <p className={`md:text-xs text-base line-clamp-1 ${isChecked ? 'text-blue-500' : 'text-[#768084]'}`}>
                          {isChecked ? "おまとめ選択中" : `${item.label}`}
                        </p>
                      </label>
                      <a
                        key={i}
                        href={
                          props.is_draft ?
                            `/${props.draft_path}/${props.prefecture}/${props.client.id}/${props.draft_id}/${item.id}${window.location.search}`
                            :
                            `/${props.prefecture}/team/${props.client.id}/invest/${props.investment.id}/detail/${item.id}/ec_return` + (props.from ? `?from=${props.from}` : "")
                        }
                        className="link-hover-none mb-[25px] block"
                      >
                        <article className="mr-[10px] flex justify-between">
                          <div className="relative mr-[10px]">
                            <div className="w-[80px] h-[60px]">
                              <img src={item.ec_return_images[0]?.path} alt={item.name} className="object-cover" />
                            </div>
                            {item.is_new_arrival &&
                              <p className="text-xs text-white bg-[#FF0000] border-white px-1 absolute inline-block border top-0 font-bold h-[18px]">NEW</p>
                            }
                            {item.is_sold_out &&
                              <>
                                <div className="bg-black opacity-30 absolute top-0 w-full h-full" />
                                <p className="text-white underline font-bold absolute flex top-0 bottom-0 right-0 left-0 items-center text-center">SOLD OUT</p>
                              </>
                            }
                          </div>
                          <div className="w-[calc(100%-90px)]">
                            <div className="font-bold mb-1">{item.price.toLocaleString()}<span>円(税込)</span></div>
                            <h4 className="font-bold text-sm line-clamp-2">{item.name}</h4>
                          </div>
                        </article>
                      </a>
                    </>
                  </div>
                )
              })}
            </div>
            <div className={`${showReadMoreBtn ? "mb-[30px] mx-auto w-[150px] h-[40px]": "hidden"}`}>
              <SecondaryButton
                text="Read more"
                iconRight="icon-arrow-under-small"
                fontRaleway={true}
                onClick={showAllProducts}
              />
            </div>
          </section>
        )}
        {/* <section className="px-[30px] pt-[60px] recent-activity sm:pt-[100px] sm:max-w-main sm:mx-auto sm:pb-0 lg:px-0">
          <h3 className="lg:mb-[60px] mb-10 lg:font-bold">最近の活動状況</h3>
          <div className="mr-[-30px] pb-[60px] flex overflow-x-auto lg:hidden">
            {[1, 2, 3, 4, 5, 6].map((i: number) => (
              <section key={i} className="last:mr-0 mr-4">
                <div className="w-[150px] h-[150px] mb-[10px]">
                  <img
                    src="https://placehold.jp/150x150.png"
                    alt=""
                    className="object-cover"
                  />
                </div>
                <div className="text-[#768084] text-[10px] mb-[10px]">
                  Namenamename/1時間前
                </div>
                <h1 className="mb-4 text-sm font-bold">
                  いつも住田選手を応援いただきありがとうございます。みなさま、ついに明日広島ホームテレビ(5ch)・5up内で住田選手の特集が明日放送予定です。TV欄にも登場しました...
                </h1>
                <div className="flex">
                  <div className="text-[#768084] pr-[10px] border-[#D2DFE6] text-[10px] border-r">
                    コメント100
                  </div>
                  <div className="text-[#768084] pl-[10px] text-[10px] left-icon icon-heart flex items-center underline">
                    100
                  </div>
                </div>
              </section>
            ))}
          </div>
          <div className="pb-[60px] hidden lg:block slider-col-5">
            <SliderWrapper>
              <Slider {...cardCol5}>
                {[1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
                  <section key={i} className="last:mr-0 mr-4">
                    <div className="w-[180px] h-[180px] mb-[10px]">
                      <img
                        src="https://placehold.jp/150x150.png"
                        alt=""
                        className="object-cover"
                      />
                    </div>
                    <div className="text-[#768084] text-[10px] mb-[10px] w-[180px]">
                      Namenamename/1時間前
                    </div>
                    <h1 className="w-[180px] mb-4 text-sm font-bold">
                      いつも住田選手を応援いただきありがとうございます。みなさま、ついに明日広島ホームテレビ(5ch)・5up内で住田選手の特集が明日放送予定です。TV欄にも登場しました...
                    </h1>
                    <div className="w-[180px] flex">
                      <div className="text-[#768084] pr-[10px] border-[#D2DFE6] text-[10px] border-r">
                        コメント100
                      </div>
                      <div className="text-[#768084] pl-[10px] text-[10px] left-icon icon-heart flex items-center underline">
                        100
                      </div>
                    </div>
                  </section>
                ))}
              </Slider>
            </SliderWrapper>
          </div>
        </section> */}
        <section className="project-detail-tab-area">
          <div className="block sm:hidden">
            <div className="border-[#1F2122] border-t">
              <div
                className={`px-[30px] right-icon flex justify-between py-6 leading-none items-center ${
                  isOpenOverviewAccordion ? "icon-arrow-top" : "icon-arrow-under"
                }`}
                onClick={() => toggleOverviewAccordion()}
                id="overview"
              >
                プロジェクト概要
              </div>
              <div
                className={`p-[30px] bg-[#F4F6F8] ${
                  isOpenOverviewAccordion ? "block" : "hidden"
                }`}
              >
                <p className="text-xl font-bold">{props.investment.title}</p>
                <div
                  className="overview"
                  dangerouslySetInnerHTML={{
                  __html: props.investment.explanation,
                }} />
              </div>
            </div>
            <div className="border-[#1F2122] border-t">
              <div
                className={`px-[30px] right-icon flex justify-between py-6 leading-none items-center ${
                  isOpenCommunityAccordion ? "icon-arrow-top" : "icon-arrow-under"
                }`}
                id="community"
                onClick={() => toggleCommunityAccordion()}
              >
                コミュニティ({props.articles_length})
              </div>
              <div
                className={`bg-[#F4F6F8] p-[30px] ${
                  isOpenCommunityAccordion ? "block" : "hidden"
                }`}
              >
                <Community
                  clientID={props.client.id}
                  investmentID={props.investment.id}
                  canPostArticle={props.can_post_article}
                  currentUserClientID={props.current_user_client_id}
                />
              </div>
            </div>
            {props.investment.referral_incentive_description &&
              <div className="border-[#1F2122] border-t">
                <div
                  className={`px-[30px] right-icon flex justify-between py-6 leading-none items-center ${
                    isOpenReferralAccordion
                      ? "icon-arrow-top"
                      : "icon-arrow-under"
                  }`}
                  onClick={() => toggleReferralAccordion()}
                  id="referral"
                >
                  紹介
                </div>
                <div
                  className={`p-[30px] bg-[#F4F6F8] ${
                    isOpenReferralAccordion ? "block" : "hidden"
                  }`}
                >
                  <Introduction
                    data={props.investment.referral_totalizations}
                    description={props.investment.referral_incentive_description}
                    widgetURL={props.widget_url}
                    referralURL={props.referral_url}
                    investmentTitle={props.investment.title}
                  />
                </div>
              </div>
            }
            {props.client.twitter_account &&
              <div className="border-[#1F2122] border-t">
                <div
                  className={`px-[30px] right-icon flex justify-between py-6 leading-none items-center ${
                    isOpenTwitterAccordion ? "icon-arrow-top" : "icon-arrow-under"
                  }`}
                  onClick={() => toggleTwitterAccordion()}
                  id="post"
                >
                  ポスト
                </div>
                <div
                  className={`p-[30px] bg-[#F4F6F8] ${
                    isOpenTwitterAccordion ? "block" : "hidden"
                  }`}
                >
                  <Tweet account={props.client.twitter_account} />
                </div>
              </div>
            }
            {props.investment.questionnaire.title &&
              <div className="border-[#1F2122] border-t">
                <div
                  className={`px-[30px] right-icon flex justify-between py-6 leading-none items-center ${
                    isOpenQuestionnaireAccordion
                      ? "icon-arrow-top"
                      : "icon-arrow-under"
                  }`}
                  onClick={() => toggleQuestionnaireAccordion()}
                  id="questionnaire"
                >
                  アンケート
                </div>
                <div
                  className={`p-[30px] bg-[#F4F6F8] ${
                    isOpenQuestionnaireAccordion ? "block" : "hidden"
                  }`}
                >                
                  <Questionnaire
                    questionnaire_totalizations={
                      props.investment.questionnaire_totalizations
                    }
                    questionnaire_answer={props.questionnaire_answer}
                    questionnaire={props.investment.questionnaire}
                  />
                </div>
              </div>
            }
          </div>
          <div className="bg-[#F4F6F8] pb-[90px] hidden justify-center pt-6 sm:flex sm:pb-0">
            <div className="top-[100px] pb-[160px] mr-[60px] ml-[-160px] text-[#768084] project-detail-side-menu sticky hidden flex-col h-full text-right md:flex">
              <div
                className="active item mb-4 text-sm cursor-pointer"
                onClick={(e) => changeTab(e)}
                ref={overviewTab.ref}
              >
                プロジェクト概要
              </div>
              <div
                className="item mb-4 text-sm cursor-pointer"
                id="community"
                ref={communityTab.ref}
                onClick={(e) => changeTab(e)}
              >
                コミュニティ
              </div>
              {props.investment.referral_incentive_description &&
                <div
                  className="item mb-4 text-sm cursor-pointer"
                  onClick={(e) => changeTab(e)}
                >
                  紹介
                </div>
              }
              {props.client.twitter_account &&
                <div
                  className="item mb-4 text-sm cursor-pointer"
                  onClick={(e) => changeTab(e)}
                >
                  ポスト
                </div>
              }
              {props.investment.questionnaire.title &&
                <div
                  className="item text-sm cursor-pointer"
                  onClick={(e) => changeTab(e)}
                >
                  アンケート
                </div>
              }
            </div>
            <div className="w-[700px]">
              <div className="border-[#D2DFE6] text-[#768084] project-detail-tab flex border-b">
                <div
                  className="mr-[40px] active tab pb-5 cursor-pointer"
                  onClick={(e) => changeTab(e)}
                >
                  プロジェクト概要
                </div>
                <div
                  className="mr-[40px] tab cursor-pointer text-center"
                  onClick={(e) => changeTab(e)}
                >
                  コミュニティ({props.articles_length})
                </div>
                {props.investment.referral_incentive_description &&
                  <div
                    className="mr-[40px] tab pb-5 cursor-pointer"
                    onClick={(e) => changeTab(e)}
                  >
                    紹介
                  </div>
                }
                {props.client.twitter_account &&
                  <div
                    className="mr-[40px] tab pb-5 cursor-pointer"
                    onClick={(e) => changeTab(e)}
                  >
                    ポスト
                  </div>
                }
                {props.investment.questionnaire.title &&
                  <div
                    className="mr-[40px] tab pb-5 cursor-pointer"
                    onClick={(e) => changeTab(e)}
                  >
                    アンケート
                  </div>
                }
              </div>
              <div
                className="tab-content py-[80px]"

              >
                <p className="text-[22px] font-bold">{props.investment.title}</p>
                <div
                  className="overview"
                  dangerouslySetInnerHTML={{
                  __html: props.investment.explanation,
                }} />
              </div>
              <div className="tab-content hidden py-5">
                <Community
                  clientID={props.client.id}
                  investmentID={props.investment.id}
                  canPostArticle={props.can_post_article}
                  currentUserClientID={props.current_user_client_id}
                />
              </div>
              {props.investment.referral_incentive_description &&
                <div className="tab-content py-[80px] hidden">
                  <Introduction
                    data={props.investment.referral_totalizations}
                    description={props.investment.referral_incentive_description}
                    widgetURL={props.widget_url}
                    referralURL={props.referral_url}
                    investmentTitle={props.investment.title}
                  />
                </div>
              }
              {props.client.twitter_account &&
                <div className="tab-content py-[80px] hidden">
                  <Tweet account={props.client.twitter_account} />
                </div>
              }
              {props.investment.questionnaire.title &&
                <div className="tab-content py-[80px] hidden">
                  <Questionnaire
                    questionnaire_totalizations={
                      props.investment.questionnaire_totalizations
                    }
                    questionnaire_answer={props.questionnaire_answer}
                    questionnaire={props.investment.questionnaire}
                  />
                </div>
              }
            </div>
          </div>
        </section>
        <section className="px-[30px] sm:py-[50px] py-9">
          <div className="sm:max-w-main sm:mx-auto">
            <h3 className="mb-9 font-medium sm:mb-5">プロジェクトオーナー</h3>
            <div className="sm:flex sm:justify-center">
              <div className="sm:border-[#DEDEDE] sm:w-[45.8%] sm:flex sm:pr-5 sm:border-r">
                <div className="border-[#DEDEDE] flex pb-6 border-b sm:items-center sm:pb-0 sm:border-none sm:w-full">
                  <div className="w-[105px] h-[80px] sm:w-[214px] sm:h-[160px]">
                    <a href={`/${props.prefecture}/team/${props.client.id}`}>
                      <img
                        src={props.team_image}
                        alt={props.client.client_name}
                        className="object-contain"
                      />
                    </a>
                  </div>
                  <div className="w-[calc(100%-120px)] flex flex-col justify-between ml-4 sm:w-[calc(100%-230px)]">
                    <div>{props.client.client_name}</div>
                    <div className="sm:mt-5 justify-end sm:jusfity-start flex w-[200px] h-[50px]">
                      <SecondaryLinkButton
                        text="オーナー情報"
                        link={`/${props.prefecture}/team/${props.client.id}`}
                        fontSize="text-sm"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="sm:w-[240px] sm:flex sm:flex-col sm:justify-center sm:pl-5 mt-[25px] sm:mt-0">
                <div className="flex mb-9 sm:flex-col sm:mb-5">
                  <div className="hidden md:block md:w-full mb-[10px] h-[50px]">
                    <SecondaryLinkButton
                      text={`メールでのご質問はこちら`}
                      fontSize="text-sm"
                      link={`mailto:${props.investment.owner_email}`}
                      disabled={props.investment.owner_email === null}
                    />
                  </div>
                  <div className="w-[50%] mr-2 sm:w-full md:hidden sm:mr-0 sm:mb-2 h-[50px]">
                    <SecondaryLinkButton
                      text={`メールでの\nご質問はこちら`}
                      fontSize="text-sm"
                      link={`mailto:${props.investment.owner_email}`}
                      disabled={props.investment.owner_email === null}
                    />
                  </div>
                  <div className="w-[50%] sm:w-full h-[50px]">
                    <SecondaryLinkButton
                      text="公式Webサイト"
                      fontSize="text-sm"
                      link={props.client.homepage_url}
                      disabled={props.client.homepage_url === null}
                    />
                  </div>
                </div>
                <ul className="flex justify-center sm:justify-between">
                  <li className="mr-10 sm:mr-0">
                    <a href={props.client.facebook_account} className="link-hover-none">
                      <FontAwesomeIcon icon={faFacebook} size="2x" color={props.client.facebook_account ? "#1F2122" : "#D2DFE5"} />
                    </a>
                  </li>
                  <li className="mr-10 sm:mr-0">
                    <a href={props.client.twitter_account && `https://twitter.com/${props.client.twitter_account}?ref_src=twsrc%5Etfw`} className="link-hover-none">
                      <FontAwesomeIcon icon={faTwitter} size="2x" color={props.client.twitter_account ? "#1F2122" : "#D2DFE5"} />
                    </a>
                  </li>
                  <li className="mr-10 sm:mr-0">
                    <a href={props.client.instagram_account} className="link-hover-none">
                      <FontAwesomeIcon icon={faInstagram} size="2x" color={props.client.instagram_account ? "#1F2122" : "#D2DFE5"} />
                    </a>
                  </li>
                  <li>
                    <a href={props.client.youtube_account} className="link-hover-none">
                      <FontAwesomeIcon icon={faYoutube} size="2x" color={props.client.youtube_account ? "#1F2122" : "#D2DFE5"}/>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {props.client.past_projects?.length > 0 && (
          <div className="bg-[#F4F6F8]">
            <section className="px-[30px] py-[30px] max-w-main lg:py-10 mx-auto lg:px-0">
              <h3 className="mb-[30px]">過去の支援募集プロジェクト</h3>
              <div className="flex overflow-x-auto lg:hidden mr-[-30px]">
                {props.client.past_projects.map((item, i: number) => (
                  <div key={i} className="min-w-[150px] mr-[15px] last:mr-[30px] w-[150px]">
                    <PastInvestmentCard
                      title={item.title}
                      headingElement={4}
                      imgUrl={item.main_image}
                      link={`/${props.prefecture}/team/${props.client.id}/invest/${item.id}/detail`}
                      supporterCount={item.supporter_count}
                      supportMoney={item.total_amount}
                      targetAmount={item.target_amount}
                    />
                  </div>
                ))}
              </div>
              <div className="hidden lg:block slider-col-4">
                <SliderWrapper col={4}>
                  {props.client.past_projects.map((item, i) => (
                    <div key={i} className="last:mr-0 mr-4">
                      <PastInvestmentCard
                        title={item.title}
                        headingElement={4}
                        imgUrl={item.main_image}
                        link={`/${props.prefecture}/team/${props.client.id}/invest/${item.id}/detail`}
                        supporterCount={item.supporter_count}
                        supportMoney={item.total_amount}
                        targetAmount={item.target_amount}
                      />
                    </div>
                  ))}
                </SliderWrapper>
              </div>
            </section>
          </div>
        )}
      </section>
      </Layout>
    </div>
  );
};

export default Detail;
