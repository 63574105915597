import React, { useState, useEffect, useRef } from "react";
import SecondaryButton from "../../components/Common/Buttons/SecondaryButton";
import BaseButton from "../Common/Buttons/BaseButton";
import Message from "./Message";
import { HttpRequest } from "../../utils/common"
import { article } from "../../types/pages/Team/detail";
import MessageInput from "./MessageInput";
import { MAX_ARTICLE_LENGTH } from "../../utils/constants"

type Props = {
  canPostArticle: boolean;
  clientID: number;
  investmentID: number;
  currentUserClientID: number | null;
};

const Community: React.FC<Props> = (props) => {
  const { canPostArticle, clientID, investmentID, currentUserClientID } = props;
  const [showReadMoreBtn, setShowReadMoreBtn] = useState<boolean>(true)
    useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null)
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false)
  const [isFileSizeValid, setIsFileSizeValid] = useState<boolean>(true)
  const [articlesPage, setArticlesPage] = useState<number>(1);
  const [articles, setArticles] = useState<Array<article>>([]);
  const [isLoadingArticles, setIsLoadingArticles] = useState<boolean>(false);
  const [postingArticle, setPostingArticle] = useState<string>("");
  const [errors, setErrors] = useState<object>({});
  const [isPostingArticle, setIsPostingArticle] = useState<boolean>(false);
  const inputRef = useRef(null)

  useEffect(() => {
    HttpRequest.get(`/api/articles/list?client_id=${clientID}&investment_id=${investmentID}&page=${articlesPage}`)
      .then((res) => {
        setArticles(res.data);
        shouldShowReadMoreBtn(res.data)
        const currentPage = articlesPage;
        setArticlesPage(currentPage+1);
      })
  }, []);

  const uploadFile = () => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  const onFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (files && files[0]) {
      if (files[0].size > 1024 * 1024 * 10) {
        setIsFileSizeValid(false)
        return
      }
      setIsFileSizeValid(true)
      setFile(files[0]);
    }
  };

  const postArticle = async () => {
    if (isPostingArticle) return;
    setIsPostingArticle(true);
    if (postingArticle.replace(/[\s\n]/g, "") === "") {
      setErrors((other) => ({...other, article: "メッセージが空になっています。"}))
      return;
    }
    if (postingArticle.length > MAX_ARTICLE_LENGTH) {
      setErrors((other) => ({ ...other, article: "メッセージが長すぎます。" }))
      return;
    }
    const formData = new FormData()
    formData.append("client_id", clientID.toString())
    formData.append("article", postingArticle)
    formData.append("file", file)
    HttpRequest.post("/api/articles", formData)
    .then(() => {
      refreshArticles();
      setPostingArticle("");
      setFile(null);
      setIsFormOpen(false);
      setErrors({});
      setIsPostingArticle(false);
    })
    .catch((e) => {
      setErrors(e.response.data.errors);
      setIsPostingArticle(false);
      console.error(e);
    })
  }

  const listArticles = async () => {
    await HttpRequest.get(`/api/articles/list?client_id=${clientID}&investment_id=${investmentID}&page=1`)
      .then((res) => {
        setArticles(res.data);
        setArticlesPage(2);
      })
  }

  const refreshArticles = async () => {
    const currentPage = articlesPage;
    await listArticles();
    for (let i = 1; i < currentPage-1; i++) {
      await new Promise(resolve => setTimeout(resolve, 50))
      await fetchArticles();
    }
  }

  const fetchArticles = async () => {
    setIsLoadingArticles(true);
    setArticlesPage((currentPage) => {
      HttpRequest.get(`/api/articles/list?client_id=${props.clientID}&investment_id=${props.investmentID}&page=${currentPage}`)
      .then((res) => {
        setArticles((currentArticles) => currentArticles.concat(res.data));
        shouldShowReadMoreBtn(res.data)
        setIsLoadingArticles(false);
      })
      return currentPage + 1
    });
  }

  const shouldShowReadMoreBtn = (article) => {
    if (article.length < 10) {
      setShowReadMoreBtn(false)
    } else {
      setShowReadMoreBtn(true)
    }
  }

  return (
    <>
      {canPostArticle &&
        <>
          <button
            className={`${isFormOpen ? "icon-arrow-top" : "icon-arrow-under after:mb-[2px]"} border border-[#1F2122] block bg-white w-full py-3 right-icon`}
            onClick={() => setIsFormOpen(!isFormOpen)}
          >
            <span className="mr-5">投稿する</span>
          </button>
          <div className={`${isFormOpen ? "p-5 bg-white border-[#1F2122] border-b border-l border-r" : "hidden"}`}>
            <div className="mb-5">
              <textarea
                className="border border-[#B1C2CC] px-[15px] py-[10px] block w-full"
                rows={10}
                placeholder="あなたの応援メッセージを送ろう！"
                value={postingArticle}
                onChange={(e) => setPostingArticle(e.target.value)}
              />
              <p className="text-[#FF0000] text-sm mt-1">{errors["article"]}</p>
            </div>
            <div className="flex mb-5 items-center justify-center sm:justify-start flex-col sm:flex-row">
              <input
                id="file"
                type="file"
                accept=".png,.jpeg,.jpg"
                className="hidden"
                ref={inputRef}
                onChange={onFileInputChange}
              />
              <button
                className="border border-[#1F2122] rounded-default inline-block w-[140px] h-10 cursor-pointer text-center leading-10 mb-5 sm:mb-0"
                onClick={uploadFile}
              >
                ファイルを選択
              </button>
              {file && file.name &&
                <p className="ml-5 text-sm">{file.name}</p>
              }
            </div>
            {!isFileSizeValid &&
              <p className="text-[#FF0000] text-sm mb-1">※ファイルサイズが10Mバイトを超えています。</p>
            }
          <p className="text-[#FF0000] text-sm mb-1">{errors["file"]}</p>
          <div className="mb-[30px]">
            <p className="text-[#768084] text-xs mb-[10px]">最大10Mバイトまでのファイル(jgp,png形式)を添付できます。</p>
            {currentUserClientID === clientID &&
              <p className="text-[#768084] text-xs">※実行者様の投稿の内容は既に支援いただいた方へ通知メールとして配信されます。</p>
            }
          </div>
            <div className="w-[250px] h-[50px] mx-auto">
              <BaseButton
                text="投稿する"
                onClick={() => {
                  postArticle();
                }}
              />
            </div>
          </div>
        </>
      }
      {articles.map((item, i) => 
        <div className="border-b" key={i}>
          <div className={`border-[#D2DFE6] py-[30px] sm:py-5`}>
            <Message article={item} refreshArticles={refreshArticles} />     
            {item.active_comments.length > 0 && item.active_comments.map((child_item, n) =>
              <div className="border-[#1F2122] mt-[30px] pl-5 border-l ml-3" key={n}>
                <Message isChild={true} comment={child_item} articleID={item.id} />
              </div>
            )}
          </div>
          {canPostArticle &&
            <MessageInput
              articleID={item.id}
              clientID={clientID}
              refreshArticles={refreshArticles}
              currentUserClientID={currentUserClientID}
            />
          }
        </div>
      )}
      {showReadMoreBtn && (
        <div className="my-[30px] mx-auto w-[150px] h-[40px] sm:w-[330px] sm:h-[50px]">
          <SecondaryButton
            text="Read more"
            iconRight="icon-arrow-under-small"
            fontRaleway={true}
            onClick={fetchArticles}
          />
        </div>
      )}
    </>
  );
};

export default Community;
