import React from "react";
import Share from "../Common/Share";
import IntroductionImgSp from "../../../images/introduction_sp.png";
import IntroductionImgPc from "../../../images/introduction_pc.png";
import { referralTotalization } from "../../types/pages/Team/detail";

type Props = {
  data: Array<referralTotalization>;
  description: string | null;
  widgetURL: string;
  referralURL: string;
  investmentTitle: string;
};

const Introduction: React.FC<Props> = (props) => {
  return (
    <section>
      <h3 className="mb-[30px] sm:text-[22px] text-xl font-bold sm:text-center">
        スポチュニティのアカウントにログインして<br className="hidden sm:block" />紹介インセンティブをゲットしよう！
      </h3>
      <div className="mb-[80px] sm:px-[80px] sm:py-[30px] px-4 bg-white">
        <div className="py-[30px]">
          <p className="text-center text-lg font-bold">
            {props.description}
          </p>
          {/* <div className="sm:w-[327px] sm:h-[205px] mb-5 sm:mx-auto">
            <img src="https://placehold.jp/275x173.png" alt="" />
          </div>
          <div>
            <p className="mb-5 sm:text-xs">
              ①本キャンペーンは、スポチュニティ株式会社による提供です。本キャンペーンについてのお問い合わせはAmazonではお受付しておりません。お問い合わせは、
              <a href="/" className="link-hover-none underline">
                こちら
              </a>
              までお願いいたします。
            </p>
            <p className="text-xs">
              ②Amazon、Amazon.co.jpおよびそれらのロゴはAmazon.com,Inc.またはその関連会社の商標です。
            </p>
          </div> */}
        </div>
        <div className="flex justify-between">
          <div className="bg-[#1A1311] w-[calc(50%-8px)] h-[1px] after:h-[1px] after:bg-[#1A1311] after:content-[''] after:right-[-10px] after:absolute relative after:top-1 after:inline-block after:w-3 after:rotate-45"></div>
          <div className="bg-[#1A1311] w-[calc(50%-8px)] h-[1px] before:h-[1px] before:bg-[#1A1311] before:content-[''] before:left-[-10px] before:absolute relative before:top-1 before:inline-block before:w-3 before:-rotate-45"></div>
        </div>
        <Share
          widgetURL={props.widgetURL}
          referralURL={props.referralURL}
          investmentTitle={props.investmentTitle}
        />
      </div>
      <section className="mb-[80px]">
        <div className="mb-[50px]">
          <h4 className="mb-[30px] text-xl font-bold">紹介方法について</h4>
          <p>
            紹介用シェアボタンを活用して、お知り合いの方を支援者としてご紹介いただくと、抽選で特別プレゼントがあります。ぜひご紹介ください！
          </p>
        </div>
        <div>
          <div className="mb-[30px] font-bold">紹介STEP</div>
          <div className="flex items-center justify-center mb-5 py-5 bg-white">
            <div className="w-[74.6vw] h-[48.5vw] sm:hidden">
              <img src={IntroductionImgSp} alt="紹介方法" />
            </div>
            <div className="w-[510px] h-[180px] hidden sm:block">
              <img src={IntroductionImgPc} alt="紹介方法" />
            </div>
          </div>
          <ol className="sm:flex sm:justify-between">
            <li className="mb-6 sm:w-[210px]">
              <div className="sm:text-sm mb-[10px] after:left-[14px] after:content-['1'] h-[40px] border-[#1F2122] before:trapezoid before:content-[''] after:absolute relative flex before:inline-block items-center before:mr-3 after:text-white bg-white border">
                シェア
              </div>
              <p className="sm:text-sm">
                プロジェクトページ「紹介」タブのFacebook、Twitter、Lineのいずれかからシェア
              </p>
            </li>
            <li className="mb-6 sm:w-[210px]">
              <div className="sm:text-sm mb-[10px] after:left-[14px] after:content-['2'] h-[40px] border-[#1F2122] before:trapezoid before:content-[''] after:absolute relative flex before:inline-block items-center before:mr-3 after:text-white bg-white border">
                支援完了
              </div>
              <p className="sm:text-sm">
                紹介された方がそのシェアされたURLからログインし、ご支援いただく
              </p>
            </li>
            <li className="sm:w-[210px]">
              <div className="sm:text-sm mb-[10px] after:left-[14px] after:content-['3'] h-[40px] border-[#1F2122] before:trapezoid before:content-[''] after:absolute relative flex before:inline-block items-center before:mr-3 after:text-white bg-white border">
                支援完了メール<span className="text-xs">(自動)</span>
              </div>
              <p className="sm:text-sm">支援完了メール（自動）が届く</p>
            </li>
          </ol>
        </div>
      </section>
      {props.data.length > 0 &&
        <section className="mb-[30px]">
          <div className="mb-[30px]">
            <h4 className="mb-[30px] text-xl font-bold">紹介人数TOP30</h4>
            <p>
              このプロジェクトを紹介してくださった素敵な皆さま、有難うございます！
            </p>
          </div>
          <ul className="border-[#D2DFE6] p-4 border sm:p-[30px]">
            {props.data.map((item, i: number) => (
              <li key={i} className="flex last:mb-0 mb-5">
                <div className="mr-[10px] bg-[#1F2122] text-[10px] flex items-center justify-center w-5 h-5 text-white rounded-default">
                  {i+1}
                </div>
                <div className="w-[calc(100%-30px)]">
                  <div>{item.account_name}</div>
                  <div className="text-[#525252] text-[10px]">紹介人数:{item.value}人</div>
                  <div className="h-[5px] bg-[#1F2122]" style={{width: `calc(100%*${item.value/props.data[0].value}`}}></div>
                </div>
              </li>
            ))}
          </ul>
        </section>
      }
      <Share
        widgetURL={props.widgetURL}
        referralURL={props.referralURL}
        investmentTitle={props.investmentTitle}
      />
    </section>
  );
};

export default Introduction;
