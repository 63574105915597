import React, { useEffect, useRef, useState } from "react";
import { HttpRequest } from "../../utils/common";
import Logo from "../../../images/logo.png";
import BaseLinkButton from "./Buttons/BaseLinkButton";
import SimulatorBanner from "../../../images/simulator_banner.jpg"
import CloumnBanner from "../../../images/column_banner.jpg"
import InternshipBanner from "../../../images/internship_banner.jpg"
import RecruitBanner from "../../../images/recruit_banner.jpg"
import RankingBanner from "../../../images/ranking_banner.jpg"
import CloseBtn from "../../../images/icons/icon_close.png"
import "../../../stylesheets/header.scss"
import { useSearchInput } from "../../hooks/Yukari/useSearchInput";
import SecondaryLinkButton from "./Buttons/SecondaryLinkButton";

type Props = {
  currentEmail: string | null;
  currentUserName: string | null;
  teamManager: boolean;
  managementSiteUrl: string;
  showProjectMenu: boolean;
};

const Header: React.FC<Props> = (props) => {
  const { currentEmail, currentUserName, teamManager, managementSiteUrl, showProjectMenu } = props
  const [isModal1Active, setIsModal1Active] = useState<boolean>(false)
  const [isModal2Active, setIsModal2Active] = useState<boolean>(false)
  const [isModalOverlayActive, setIsModalOverlayActive] = useState<boolean>(false)
  const [isHamburgerMenuActive, setIsHamburgerMenuActive] = useState<boolean>(false)
  const hamburgerRef = useRef(null)
  const { value, handleChange, search, setValue } = useSearchInput("")

  const openModal1 = () => {
    setIsModal1Active(true)
    setIsModal2Active(false)
    setIsModalOverlayActive(true)
  }

  const openModal2 = () => {
    setIsModal1Active(false)
    setIsModal2Active(true)
    setIsModalOverlayActive(true)
  }

  const closeModal = () => {
    setIsModal1Active(false)
    setIsModal2Active(false)
    setIsHamburgerMenuActive(false)
    setIsModalOverlayActive(false)
    if (isHamburgerMenuActive) {
      hamburgerRef?.current.classList.remove("icon-close")
      hamburgerRef?.current.classList.add("icon-menu")
    }
  }

  const toggleHamburgerMenu = (e) => {
    e.target.classList.toggle("icon-menu")
    e.target.classList.toggle("icon-close")
    setIsHamburgerMenuActive(!isHamburgerMenuActive)
    if (isModal1Active || isModal2Active) {
      setIsModalOverlayActive(true)
    } else {
      setIsModalOverlayActive(!isModalOverlayActive)
    }
    setIsModal1Active(false)
    setIsModal2Active(false)
  }

  const resetValue = () => {
    setValue("")
  }

  useEffect(() => {
    const wrapper: HTMLElement | null = document.querySelector(".wrapper");
    const header: HTMLElement | null = document.querySelector(".header");
    const logo: HTMLElement | null = document.querySelector(".logo");
    const dropdownMenu: NodeListOf<HTMLElement> | null = document.querySelectorAll(".dropdown-menu-pc")

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 0) {
        wrapper.style.height = "60px";
        header.style.height = "60px";
        logo.style.width = "85px";
        logo.style.height = "16px";

        for (let i = 0; i < dropdownMenu.length; i++) {
          dropdownMenu[i].style.top = "60px"
        }
      } else {
        wrapper.style.height = "70px";
        header.style.height = "70px";
        logo.style.width = "124px";
        logo.style.height = "22px";

        for (let i = 0; i < dropdownMenu.length; i++) {
          dropdownMenu[i].style.top = "70px"
        }
      }
    });

    const parentNav: NodeListOf<HTMLElement> | null = document.querySelectorAll(".parent-nav-item-pc")
    const parentNavInner: NodeListOf<HTMLElement> | null = document.querySelectorAll(".parent-nav-item-inner-pc")
    const overlay: HTMLElement | null = document.querySelector("#header-overlay-pc")
    let currentDropdownMenu;
    let initialDropdownMenuHeight;
    let isActive = false;

    const parentNavClose = () => {
      for (let i = 0; i < parentNav.length; i++) {
        parentNav[i].classList.remove("open")
        const innerItem = dropdownMenu[i].querySelector('div')
        parentNavInner[i].style.backgroundSize = "0 2px"
        parentNavInner[i].style.backgroundPositionX = "right"
        dropdownMenu[i].style.display = "none"
        innerItem.style.opacity = "0"
        dropdownMenu[i].style.height = "0px"
      }
    }

    const parentNavOpen = (element:HTMLElement) => {
      element.classList.add("open")
      overlay.classList.remove("hidden")
      const dropdownMenu:HTMLElement = element.querySelector('.dropdown-menu-pc')
      const innerItem:HTMLElement = dropdownMenu.querySelector('div')
      const parentNavInner:HTMLElement = element.querySelector('.parent-nav-item-inner-pc')
      dropdownMenu.style.display = "block"
      dropdownMenu.style.height = "auto"
      parentNavInner.style.backgroundSize = "100% 2px"
      parentNavInner.style.backgroundPositionX = "left"
      currentDropdownMenu = dropdownMenu

      requestAnimationFrame(() => {
        initialDropdownMenuHeight = dropdownMenu.clientHeight
        dropdownMenu.style.height = "0px"
        requestAnimationFrame(() => {
          innerItem.style.opacity = "1"
          dropdownMenu.style.height = `${initialDropdownMenuHeight}px`
        })
      })
    }

    for (let i = 0; i < parentNavInner.length; i++) {
      parentNavInner[i].addEventListener("click", () => {
        if (isActive) {
          reset()
          if (parentNav[i].classList.contains("open")) {
            parentNavClose()
            overlay.classList.add("hidden")
            isActive = false;
          } else {
            parentNavClose()
            setTimeout(() => {
              parentNavOpen(parentNav[i])
            },50)
            isActive = true;
          }
        } else {
          parentNavOpen(parentNav[i]); 
          isActive = true;
        }
      })
    }

    overlay.addEventListener("click", () => {
      parentNavClose()
      overlay.classList.add("hidden")
    })

    const children: NodeListOf<HTMLElement> | null = document.querySelectorAll(".child-nav-item-pc")
    const grandchildren: NodeListOf<HTMLElement> | null = document.querySelectorAll(".grandchildren-list")

    const hideChildren = () => {
      grandchildren.forEach(el => {
        el.style.display = "none"
        el.style.opacity = "0"
      })
    }

    const resetParentStyle = () => {
      children.forEach(el => {
        el.style.fontWeight = "normal"
        el.classList.add("icon-arrow-right")
        el.classList.remove("icon-arrow-right-bold")
        el.classList.remove("active")
      })
    }

    const reset = () => {
      resetParentStyle()
      hideChildren()
      currentDropdownMenu.style.height = `${initialDropdownMenuHeight}px`
    }

    for (let i = 0; i < children.length; i++) {
      children[i].addEventListener("click", () => {
        if (!children[i].classList.contains("active")) {
          reset()
          children[i].classList.add("active")
          children[i].style.fontWeight = "bold"
          children[i].classList.remove("icon-arrow-right")
          children[i].classList.add("icon-arrow-right-bold")
          grandchildren[i].style.display = "block"

          requestAnimationFrame(() => {
            const childrenHeight = grandchildren[i].clientHeight
            if (childrenHeight > currentDropdownMenu.clientHeight - 60) {
              currentDropdownMenu.style.height = `${childrenHeight + 60}px`
            }
            requestAnimationFrame(() => {
              grandchildren[i].style.opacity = "1"
            })
          })
        }
      })
    }
  }, []);

  return (
    <>
      <div className="wrapper fixed z-50 hidden w-screen bg-white sm:block">
        <header className="h-[70px] header flex items-center justify-between mx-auto my-0 px-5 max-w-header relative">
          <div className="flex items-center">
            <h1 className="w-[124px] h-[22px] logo">
              <a href="/" className="link-hover-none inline-block">
                <img src={Logo} alt="spportunity" />
              </a>
            </h1>
            <nav className="ml-[30px]">
              <ul className="flex">
                <li className="parent-nav-item-pc h-[70px] leading-[70px] px-[18px]">
                  <span className="text-sm hover:cursor-pointer parent-nav-item-inner-pc">
                    プロジェクトをはじめる
                  </span>
                  <div className="bg-[#F4F6F8] absolute w-screen mx-[calc(50%-50vw)] left-0 top-[70px] dropdown-menu-pc">
                    <div className="max-w-header w-header mx-auto pt-5 pb-[30px] md:px-[64px] sm:px-[30px]">
                      <div className="flex justify-between mb-[30px]">
                        <div className="w-[calc((100%-40px)/2)] h-[50px]">
                          <BaseLinkButton
                            text="プロジェクトをはじめたい"
                            fontSize="text-sm"
                            link="https://corp.spportunity.com/clubteam/"
                          />
                        </div>
                        <div className="w-[calc((100%-40px)/2)] h-[50px]">
                          <BaseLinkButton
                            text="プロジェクトを作る"
                            fontSize="text-sm"
                            link={currentEmail ? managementSiteUrl : "/accounts/sign_in"}
                            iconRightEdge="icon-external-white"
                            isExternal={!!currentEmail}
                          />
                        </div>
                      </div>
                      <div className="flex">
                        <ul className="w-[calc(100%-280px)] grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-x-7 pr-[30px] border-r border-[#D2DFE6]">
                          <li className="text-sm border-b border-[#D2DFE6] sm:pb-[15px] lg:pb-0">
                            <a href="https://corp.spportunity.com/" className="right-icon icon-stick-arrow-right flex items-center justify-between">スポチュニティとは</a>
                          </li>
                          <li className="text-sm border-b border-[#D2DFE6] sm:py-[15px] lg:py-0">
                            <a href="https://corp.spportunity.com/clubteam/" className="right-icon icon-stick-arrow-right flex items-center justify-between">スポチュニティが選ばれる理由</a>
                          </li>
                          <li className="text-sm border-b border-[#D2DFE6] sm:py-[15px] lg:py-0">
                            <a href="https://corp.spportunity.com/spportunitycharity" className="right-icon icon-stick-arrow-right flex items-center justify-between">寄付型クラウドファンディングとは</a>
                          </li>
                          {/* Phase2では非表示 */}
                          {/*
                            <li className="text-sm border-b border-[#D2DFE6] sm:py-[15px] md:py-0">
                              <a href="" className="right-icon icon-stick-arrow-right flex items-center justify-between">成功者インタビュー</a>
                            </li>
                          */}
                          {/* <li className="text-sm border-b border-[#D2DFE6] sm:py-[15px] lg:py-0">
                            <a href="https://corp.spportunity.com/spportunitycharity" className="right-icon icon-stick-arrow-right flex items-center justify-between">支援募集プロジェクトのはじめ方</a>
                          </li> */}
                          {/*
                            <li className="text-sm sm:border-b sm:border-[#D2DFE6] lg:border-none py-[15px]">
                              <a href="" className="right-icon icon-stick-arrow-right flex items-center justify-between">スポチュニティのノウハウ</a>
                            </li>
                          */}
                          <li className="text-sm sm:border-b sm:border-[#D2DFE6] md:border-none py-[15px]">
                            <a href="https://corp.spportunity.com/campaign/" className="right-icon icon-stick-arrow-right flex items-center justify-between">紹介キャッシュバックプログラム</a>
                          </li>
                        </ul>
                        <div className="w-[250px] ml-[30px]">
                          <a href="/simulator" className="block overflow-hidden link-hover-none">
                            <img src={SimulatorBanner}
                              alt="支援金額シミュレーション"
                              className="img-expansion aspect-4/3"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="px-[18px] parent-nav-item-pc h-[70px] leading-[70px]">
                  <span className="text-sm hover:cursor-pointer parent-nav-item-inner-pc">
                    プロジェクトをさがす
                  </span>
                  <div className="bg-[#F4F6F8] absolute w-screen mx-[calc(50%-50vw)] left-0 top-[70px] dropdown-menu-pc">
                    <div className="max-w-header w-header mx-auto pt-5 pb-[30px] md:px-[64px] sm:px-[30px] flex">
                      <ul className="w-[290px] pr-[30px] border-r border-[#D2DFE6]">
                        <li className="text-sm border-b border-[#D2DFE6] pb-[15px]">
                          <a href="/search/status" className="right-icon icon-stick-arrow-right flex items-center justify-between">ステータスからさがす</a>
                        </li>
                        <li className="text-sm border-b border-[#D2DFE6] py-[15px]">
                          <a href="/ranking" className="right-icon icon-stick-arrow-right flex items-center justify-between">支援金ランキングからさがす</a>
                        </li>
                        <li className="text-sm py-[15px]">
                          <a href="/search/team" className="right-icon icon-stick-arrow-right flex items-center justify-between">クラブチームからさがす</a>
                        </li>
                      </ul>
                      <div className="w-[420px] ml-[30px]">
                        <p className="text-sm mb-1">ゆかり検索/キーワード検索</p>
                        <div className="text-xs mb-[15px]">
                          <p>夢を実現するためにあなたの支援を必要としています。</p>
                          <p>まずは、あなたとゆかりのあるチームや選手のプロジェクトを探してみよう！</p>
                        </div>
                        <div className="relative">
                          <input
                            type="text"
                            placeholder="キーワードを入力してください"
                            className="w-full block pl-[10px] py-[15px] placeholder-[#B1C2CC]::placeholder text-sm pr-[30px]"
                            onKeyPress={search}
                            value={value}
                            onChange={handleChange}
                          />
                          <button type="button" className="w-3 h-3 absolute right-[10px] top-[18px]">
                            <img
                              src={CloseBtn}
                              alt="テキストボックスの中身を削除"
                              onClick={resetValue}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
          <nav>
            <ul className="flex items-center">
              {currentEmail ? (
                <>
                  {teamManager &&
                    <li className="h-[30px] w-[138px] mr-4 hidden md:block">
                      <a href={managementSiteUrl} className="left-icon icon-gear link-hover-none flex items-center justify-center w-full h-full text-xs text-white text-center !bg-[#006AC9] rounded-default">プロジェクト管理</a>
                    </li>
                  }
                  <li className="h-[30px] max-w-[calc(100vw-680px)]">
                    <a
                      href="/mypage"
                      className={`text-xs text-center w-full h-full border-[#B1C2CC] btn-hover-secondary link-hover-none border rounded-default block`}
                    >
                      <span className="flex px-2 items-center h-full">
                        <span className="icon-user flex items-center justify-center left-icon line-clamp-1">{currentUserName}</span>
                        <span className="text-xxs whitespace-nowrap">さん</span>
                      </span>
                    </a>
                  </li>
                  {/* Phase2では非表示 */}
                  {/* <li className="h-[30px] max-w-[calc(100vw-800px)]">
                    <a
                      href="/mypage"
                      className={`text-xs text-center w-full h-full border-[#B1C2CC] btn-hover-secondary link-hover-none border rounded-default block`}
                    >
                      <span className="px-2 icon-user h-full flex items-center justify-center left-icon">{currentUserName}<span className="text-xxs">さん</span></span>
                    </a>
                  </li> */}
                </>
              ) : (
                <>
                  <li className="w-[90px] h-[30px] mr-[10px]">
                    <SecondaryLinkButton
                      link="/accounts/sign_up"
                      text="新規登録"
                      iconLeft="icon-user"
                      fontSize="text-xs"
                    />
                  </li>
                  <li className="w-[90px] h-[30px]">
                    <SecondaryLinkButton
                      link="/accounts/sign_in"
                      text="ログイン"
                      iconLeft="icon-login"
                      fontSize="text-xs"
                    />
                  </li>
                </>
              )}

              <li className="pl-[25px] flex parent-nav-item-pc items-center h-[70px] leading-[70px]">
                <span className="icon-menu left-icon text-xs hover:cursor-pointer parent-nav-item-inner-pc h-4">メニュー</span>
                <div className="bg-[#F4F6F8] absolute w-screen mx-[calc(50%-50vw)] left-0 top-[70px] dropdown-menu-pc">
                  <div className="max-w-header w-header mx-auto py-[30px] lg:px-[64px] sm:px-[30px] flex items-start flex-col lg:flex-row">
                    <div className="pb-5 lg:pb-0 border-b lg:border-r border-[#D2DFE6] lg:pr-[40px] lg:w-[calc(100%-560px)] flex justify-between w-full pr-0 lg:border-b-0">
                      <ul className="w-[250px]">
                        <li
                          className="pb-[15px] leading-normal child-nav-item-pc right-icon icon-arrow-right hover:cursor-pointer border-b border-[#D2DFE6] flex justify-between items-center text-sm"
                        >
                          はじめる
                        </li>
                        <li
                          className="py-[15px] leading-normal child-nav-item-pc right-icon icon-arrow-right hover:cursor-pointer border-b border-[#D2DFE6] flex justify-between items-center text-sm"
                        >
                          さがす
                        </li>
                        <li
                          className="py-[15px] leading-normal child-nav-item-pc right-icon icon-arrow-right hover:cursor-pointer border-b border-[#D2DFE6] flex justify-between items-center text-sm"
                        >
                          スポチュニティとは
                        </li>
                        <li className="nav-item py-[15px] leading-normal border-b border-[#D2DFE6] text-sm">
                          <a href="https://corp.spportunity.com/faq/" className="right-icon icon-stick-arrow-right flex justify-between items-center">
                            よくあるご質問
                          </a>
                        </li>
                        <li className="nav-item py-[15px] leading-normal border-b border-[#D2DFE6] text-sm">
                          <a href="https://corp.spportunity.com/inquiry/" className="right-icon icon-stick-arrow-right flex justify-between items-center">
                            お問い合わせ
                          </a>
                        </li>
                        {/* Phase2では非表示 */}
                        {/* 
                          <li className="nav-item py-[15px] leading-normal text-sm">
                            <a href="" className="right-icon icon-stick-arrow-right flex justify-between items-center ">
                              公式SNS一覧
                            </a>
                          </li>
                        */}
                      </ul>
                      <div className="w-[250px]">
                        <ul className="grandchildren-list">
                          <li className="leading-normal mb-5">
                            <a href="https://corp.spportunity.com/clubteam" className="icon-stick-arrow-right right-icon flex justify-between items-center text-sm">プロジェクトをはじめたい</a>
                          </li>
                          <li className="leading-normal mb-5">
                            <a href={currentEmail ? managementSiteUrl : "/accounts/sign_in"} className="icon-external right-icon flex justify-between items-center text-sm" target="_blank">プロジェクトを作る</a>
                          </li>
                          <li className="leading-normal mb-5">
                            <a href="/simulator" className="icon-stick-arrow-right right-icon flex justify-between items-center text-sm">支援金額シミュレーション</a>
                          </li>
                          <li className="leading-normal mb-5">
                            <a href="https://corp.spportunity.com/reason/" className="icon-stick-arrow-right right-icon flex justify-between items-center text-sm">スポチュニティが選ばれる理由</a>
                          </li>
                          {/* Phase2では非表示 */}
                          {/*
                            <li className="leading-normal mb-5">
                              <a href="" className="icon-stick-arrow-right right-icon flex justify-between items-center text-sm">成功者インタビュー</a>
                            </li>
                          */}
                          {/* <li className="leading-normal mb-5">
                            <a href="https://corp.spportunity.com/clubteam/" className="icon-stick-arrow-right right-icon flex justify-between items-center text-sm">支援募集プロジェクトのはじめ方</a>
                          </li> */}
                          {/*
                            <li className="leading-normal mb-5">
                              <a href="" className="icon-stick-arrow-right right-icon flex justify-between items-center text-sm">スポチュニティのノウハウ</a>
                            </li>
                          */}
                          <li className="leading-normal mb-5">
                            <a href="https://corp.spportunity.com/spportunitycharity" className="icon-stick-arrow-right right-icon flex justify-between items-center text-sm">寄付型クラウドファンディングとは</a>
                          </li>
                          <li className="leading-normal mb-5">
                            <a href="https://corp.spportunity.com/campaign/" className="icon-stick-arrow-right right-icon flex justify-between items-center text-sm">紹介キャッシュバックプログラム</a>
                          </li>
                        </ul>
                        <ul className="grandchildren-list">
                          <li className="leading-normal mb-5">

                            <a href="/search/status" className="icon-stick-arrow-right right-icon flex justify-between items-center text-sm">ステータスからさがす</a>
                          </li>
                          <li className="leading-normal mb-5">
                            <a href="/ranking" className="icon-stick-arrow-right right-icon flex justify-between items-center text-sm">支援金ランキングからさがす</a>
                          </li>
                          <li className="leading-normal mb-5">
                            <a href="/search/team" className="icon-stick-arrow-right right-icon flex justify-between items-center text-sm">クラブチームからさがす</a>
                          </li>
                          <li className="leading-normal mb-5">
                            <a href="/search" className="icon-stick-arrow-right right-icon flex justify-between items-center text-sm">ゆかり検索/キーワード検索</a>
                          </li>
                        </ul>
                        <ul className="grandchildren-list">
                          <li className="leading-normal mb-5">
                            <a href="https://corp.spportunity.com/about_us/" className="icon-stick-arrow-right right-icon flex justify-between items-center text-sm">スポチュニティとは</a>
                          </li>
                          <li className="leading-normal mb-5">
                            <a href="https://corp.spportunity.com/vision_mission/" className="icon-stick-arrow-right right-icon flex justify-between items-center text-sm">スポチュニティの理念</a>
                          </li>
                          <li className="leading-normal mb-5">
                            <a href="https://corp.spportunity.com/ambassador/" className="icon-stick-arrow-right right-icon flex justify-between items-center text-sm">スポチュニティ・アンバサダー</a>
                          </li>
                          <li className="leading-normal mb-5">
                            <a href="https://corp.spportunity.com/users_voice/" className="icon-stick-arrow-right right-icon flex justify-between items-center text-sm">利用者の"生の声"</a>
                          </li>
                          <li className="leading-normal mb-5">
                            <a href="https://corp.spportunity.com/original_return/" className="icon-stick-arrow-right right-icon flex justify-between items-center text-sm">オリジナルリターン</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <ul className="mt-5 lg:mt-0 lg:ml-[30px] lg:w-[530px] flex-wrap flex justify-between w-full ml-0">
                      <li className="w-[250px] mb-[30px]">
                        <a href="https://media.spportunity.com/" className=" block link-hover-none">
                          <div className="w-[250px] h-[80px] overflow-hidden">
                            <img
                              src={CloumnBanner}
                              alt="スポチュニティコラム"
                              className="img-expansion aspect-4/3 object-cover"
                            />
                          </div>
                          <p className="bg-white h-8 leading-8 px-[10px] text-sm">スポチュニティコラム</p>
                        </a>
                      </li>
                      <li className="w-[250px] mb-[30px]">
                        <a href="/ranking" className="block link-hover-none">
                          <div className="w-[250px] h-[80px] overflow-hidden">
                            <img
                              src={RankingBanner}
                              alt="スポチュニティランキング"
                              className="img-expansion aspect-4/3 object-cover"
                            />
                          </div>
                        </a>
                        <p className="bg-white h-8 leading-8 px-[10px] text-sm">スポチュニティランキング</p>
                      </li>
                      <li className="w-[250px]">
                        <a href="https://corp.spportunity.com/internship/" className="block link-hover-none">
                          <div className="w-[250px] h-[80px] overflow-hidden">
                            <img
                              src={InternshipBanner}
                              alt="インターンシップ生募集"
                              className="img-expansion aspect-4/3 object-cover"
                            />
                          </div>
                          <p className="bg-white h-8 leading-8 px-[10px] text-sm">インターンシップ生募集</p>
                        </a>
                      </li>
                      <li className="w-[250px]">
                        <a href="https://corp.spportunity.com/recruit/" className="block link-hover-none">
                          <div className="w-[250px] h-[80px] overflow-hidden">
                            <img
                              src={RecruitBanner}
                              alt="採用情報"
                              className="img-expansion aspect-4/3 object-cover"
                            />
                          </div>
                          <p className="bg-white h-8 leading-8 px-[10px] text-sm">採用情報</p>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </nav>
        </header>
      </div>
      <div className="z-20 fixed top-0 bottom-0 left-0 right-0 bg-black opacity-60 hidden" id="header-overlay-pc"></div>
      <div
        onClick={() => closeModal()}
        className={`${isModalOverlayActive ? "block" : "hidden"} sm:hidden fixed z-50 top-0 w-screen h-screen bg-black opacity-60`}
      >  
      </div>
      <div
        onClick={() => closeModal()}
        className={`${isModalOverlayActive ? "block" : "hidden"} sm:hidden fixed z-50 top-0 w-screen h-screen bg-black opacity-60`}
      >  
      </div>
      <header className="fixed z-50 w-screen bg-white sm:hidden">
        <div className="h-[50px] pl-[20px] pr-[10px] border-[#D2DFE6] flex items-center justify-between border-b border-solid">
          <h1 className="w-[84px] h-[16px]">
            <a href="/">
              <img src={Logo} alt="spportunity" />
            </a>
          </h1>
          <nav>
            <ul className="flex items-center">
              {currentEmail ? (
                <li className="h-[30px] max-w-[140px]">
                  <a
                    href="/mypage"
                    className={`text-xs text-center w-full h-full border-[#B1C2CC] btn-hover-secondary link-hover-none border rounded-default block`}
                  >
                    <span className="flex px-2 items-center h-full">
                      <span className="icon-user flex items-center justify-center left-icon line-clamp-1">{currentUserName}</span>
                      <span className="text-xxs whitespace-nowrap">さん</span>
                    </span>
                  </a>
                </li>
              ) : (
                  <>
                    <li className="w-[90px] h-[30px] mr-[5px]">
                      <SecondaryLinkButton
                        link="/accounts/sign_up"
                        text="新規登録"
                        iconLeft="icon-user"
                        fontSize="text-xs"
                      />
                    </li>
                    <li className="w-[90px] h-[30px]">
                      <SecondaryLinkButton
                        link="/accounts/sign_in"
                        text="ログイン"
                        iconLeft="icon-login"
                        fontSize="text-xs"
                      />
                    </li>
                  </>
              )}
              <li className="ml-[10px]">
                <button
                  className="text-xs icon-menu left-icon flex items-center"
                  onClick={(e) => toggleHamburgerMenu(e)}
                  ref={hamburgerRef}
                >
                  メニュー
                </button>
              </li>
            </ul>
          </nav>
        </div>
        <nav className={`${isHamburgerMenuActive ? "block" : "hidden"} bg-[#F4F6F8] p-10 max-h-[85vh] overflow-y-scroll absolute right-0 left-0`}>
          <div className="pb-5 border-b border-[#D2DFE6]">
            <a href="https://media.spportunity.com/" className="block link-hover-none mb-5">
              <div className="w-full h-[25vw] overflow-hidden">
                <img
                  src={CloumnBanner}
                  alt="スポチュニティコラム"
                  className="aspect-4/3 object-cover"
                />
              </div>
              <p className="bg-white h-8 leading-8 px-[10px] text-sm">スポチュニティコラム</p>
            </a>
            <a href="/ranking" className="block link-hover-none">
              <div className="w-full h-[25vw] overflow-hidden">
                <img
                  src={RankingBanner}
                  alt="スポチュニティランキング"
                  className="aspect-4/3 object-cover"
                />
              </div>
              <p className="bg-white h-8 leading-8 px-[10px] text-sm">スポチュニティランキング</p>
            </a>
          </div>
          <ul className="mb-5">
            <li className="border-[#D2DFE6] border-b">
              <details>
                <summary className="icon-arrow-under right-icon flex items-center justify-between py-5 text-sm">はじめる</summary>
                <ul>
                  <li className="pb-5">
                    <a href="https://corp.spportunity.com/inquiry/" className="flex icon-stick-arrow-right items-center justify-between text-sm right-icon">プロジェクトをはじめたい</a>
                  </li>
                  <li className="pb-5">
                    <a href={currentEmail ? managementSiteUrl : "/accounts/sign_in"} className="flex icon-external items-center justify-between text-sm right-icon" target="_blank">プロジェクトを作る</a>
                  </li>
                  <li className="pb-5">
                    <a href="/simulator" className="flex icon-stick-arrow-right items-center justify-between text-sm right-icon">支援金額シミュレーション</a>
                  </li>
                  <li className="pb-5">
                    <a href="https://corp.spportunity.com/reason/" className="flex icon-stick-arrow-right items-center justify-between text-sm right-icon">スポチュニティが選ばれる理由</a>
                  </li>
                  {/* Phase2では非表示 */}
                  {/*
                    <li className="pb-5">
                      <a href="" className="flex icon-stick-arrow-right items-center justify-between text-sm right-icon">成功者インタビュー</a>
                    </li>
                  */}
                  {/* <li className="pb-5">
                    <a href="https://corp.spportunity.com/spportunitycharity" className="flex icon-stick-arrow-right items-center justify-between text-sm right-icon">支援募集プロジェクトのはじめ方</a>
                  </li> */}
                  {/*
                    <li className="pb-5">
                      <a href="" className="flex icon-stick-arrow-right items-center justify-between text-sm right-icon">スポチュニティのノウハウ</a>
                    </li>
                  */}
                  <li className="pb-5">
                    <a href="https://corp.spportunity.com/spportunitycharity" className="flex icon-stick-arrow-right items-center justify-between text-sm right-icon">寄付型クラウドファンディングとは</a>
                  </li>
                  <li className="pb-5">
                    <a href="https://corp.spportunity.com/campaign/" className="flex icon-stick-arrow-right items-center justify-between text-sm right-icon">紹介キャッシュバックプログラム</a>
                  </li>
                </ul>
              </details>
            </li>
            <li className="border-[#D2DFE6] border-b">
              <details>
                <summary className="icon-arrow-under right-icon flex items-center justify-between py-5 text-sm">さがす</summary>
                <ul>
                  <li className="pb-5">
                    <a href="/search/status" className="flex icon-stick-arrow-right items-center justify-between text-sm right-icon">ステータスからさがす</a>
                  </li>
                  <li className="pb-5">
                    <a href="/ranking" className="flex icon-stick-arrow-right items-center justify-between text-sm right-icon">支援金ランキングからさがす</a>
                  </li>
                  <li className="pb-5">
                    <a href="/search/team" className="flex icon-stick-arrow-right items-center justify-between text-sm right-icon">クラブチームからさがす</a>
                  </li>
                  <li className="pb-5">
                    <a href="/search" className="flex icon-stick-arrow-right items-center justify-between text-sm right-icon">ゆかり検索/キーワード検索からさがす</a>
                  </li>
                </ul>
              </details>
            </li>
            <li className="border-[#D2DFE6] border-b">
              <details>
                <summary className="icon-arrow-under right-icon flex items-center justify-between py-5 text-sm">スポチュニティとは</summary>
                <ul>
                  <li className="pb-5">
                    <a href="https://corp.spportunity.com/about_us/" className="flex icon-stick-arrow-right items-center justify-between text-sm right-icon">スポチュニティとは</a>
                  </li>
                  <li className="pb-5">
                    <a href="https://corp.spportunity.com/vision_mission/" className="flex icon-stick-arrow-right items-center justify-between text-sm right-icon">スポチュニティの理念</a>
                  </li>
                  <li className="pb-5">
                    <a href="https://corp.spportunity.com/ambassador/" className="flex icon-stick-arrow-right items-center justify-between text-sm right-icon">スポチュニティ・アンバサダー</a>
                  </li>
                  <li className="pb-5">
                    <a href="https://corp.spportunity.com/users_voice/" className="flex icon-stick-arrow-right items-center justify-between text-sm right-icon">利用者の"生の声"</a>
                  </li>
                  <li className="pb-5">
                    <a href="https://corp.spportunity.com/original_return/" className="flex icon-stick-arrow-right items-center justify-between text-sm right-icon">オリジナルリターン</a>
                  </li>
                </ul>
              </details>
            </li>
            <li className="border-[#D2DFE6] border-b">
              <a href="https://corp.spportunity.com/faq/" className="link-hover-none icon-stick-arrow-right right-icon flex justify-between items-center !py-5">よくあるご質問</a>
            </li>
            <li className="border-[#D2DFE6] border-b">
              <a href="https://corp.spportunity.com/faq/" className="link-hover-none icon-stick-arrow-right right-icon flex justify-between items-center !py-5">お問い合わせ</a>
            </li>
            {/* Phase2では非表示 */}
            {/*
              <li className="border-[#D2DFE6] border-b">
                <a href="" className="link-hover-none icon-stick-arrow-right right-icon flex justify-between items-center !py-5">公式SNS一覧</a>
              </li>
            */}
          </ul>
          <a href="https://corp.spportunity.com/recruit/" className="block link-hover-none mb-5">
            <div className="w-full h-[25vw] overflow-hidden">
              <img
                src={RecruitBanner}
                alt="採用情報"
                className="aspect-4/3 object-cover"
              />
            </div>
            <p className="bg-white h-8 leading-8 px-[10px] text-sm">採用情報</p>
          </a>
          <a href="https://corp.spportunity.com/internship/" className="block link-hover-none mb-5">
            <div className="w-full h-[25vw] overflow-hidden">
              <img
                src={InternshipBanner}
                alt="インターンシップ生募集"
                className="aspect-4/3 object-cover"
              />
            </div>
            <p className="bg-white h-8 leading-8 px-[10px] text-sm">インターンシップ生募集</p>
          </a>
          <div className="flex justify-center mt-10">
            <button
              onClick={() => closeModal()}
              className="icon-close left-icon w-[180px] h-[40px] flex items-center justify-center font-raleway bg-white rounded-default opacity-80"
            >
              Close
            </button>
          </div>
        </nav>
        <nav className="border-[#D2DFE6] border-b border-solid">
          {showProjectMenu &&
            <ul className="h-[40px] flex items-center">
              <li className="w-[50%] border-[#D2DFE6] text-center border-r border-solid">
                <button
                  onClick={() => openModal1()}
                  className={`${isModal1Active && "bg-[#F4F6F8]"} h-[40px] leading-[40px] block text-sm w-full`}
                >
                  プロジェクトをはじめる
                </button>
              </li>
              <li className="w-[50%] text-center">
                <button
                  onClick={() => openModal2()}
                  className={`${isModal2Active && "bg-[#F4F6F8]"} h-[40px] leading-[40px] block text-sm w-full`}
                >
                  プロジェクトをさがす
                </button>
              </li>
            </ul>
          }
        </nav>
        <div className={`${isModal1Active ? "block" : "hidden"} px-10 py-[30px] bg-[#F4F6F8] h-[80vh] overflow-y-scroll`}>
          <div className="mb-5">
            <div className="mb-[10px] h-[50px]">
              <BaseLinkButton
                text="プロジェクトをはじめたい"
                fontSize="text-sm"
                link="https://corp.spportunity.com/clubteam/"
              />
            </div>
            <div className="h-[50px]">
              <BaseLinkButton
                text="プロジェクトを作る"
                fontSize="text-sm"
                link={currentEmail ? managementSiteUrl : "/accounts/sign_in"}
                iconRightEdge="icon-external-white"
                isExternal={!!currentEmail}
              />
            </div>
          </div>
          <div className="w-full">
            <a href="/simulator" className="link-hover-none">
              <img
                src={SimulatorBanner}
                alt="支援金額シミュレーション"
                className="aspect-4/3"
              />
              </a>
          </div>
          <ul>
            <li className="py-5 border-b border-[#D2DFE6]">
              <a href="https://corp.spportunity.com/reason/" className="icon-stick-arrow-right right-icon text-sm flex items-center justify-between">スポチュニティが選ばれる理由</a>
            </li>
            {/* Phase2では非表示 */}
            {/*
              <li className="py-5 border-b border-[#D2DFE6]">
                <a href="" className="icon-stick-arrow-right right-icon text-sm flex items-center justify-between">成功者インタビュー</a>
              </li>
            */}
            {/* <li className="py-5 border-b border-[#D2DFE6]">
              <a href="https://corp.spportunity.com/spportunitycharity" className="icon-stick-arrow-right right-icon text-sm flex items-center justify-between">支援募集プロジェクトのはじめ方</a>
            </li> */}
            {/*
              <li className="py-5 border-b border-[#D2DFE6]">
                <a href="" className="icon-stick-arrow-right right-icon text-sm flex items-center justify-between">スポチュニティのノウハウ</a>
              </li>
            */}
            <li className="py-5 border-b border-[#D2DFE6]">
              <a href="https://corp.spportunity.com/spportunitycharity" className="icon-stick-arrow-right right-icon text-sm flex items-center justify-between">寄付型クラウドファンディングとは</a>
            </li>
            <li className="py-5 border-b border-[#D2DFE6]">
              <a href="https://corp.spportunity.com/campaign/" className="icon-stick-arrow-right right-icon text-sm flex items-center justify-between">紹介キャッシュバックプログラム</a>
            </li>
          </ul>
          <div className="flex justify-center mt-10">
            <button
              className="icon-close left-icon w-[180px] h-[40px] flex items-center justify-center font-raleway bg-white rounded-default opacity-80"
              onClick={() => closeModal()}
            >
              Close
            </button>
          </div>
        </div>
        <div className={`${isModal2Active ? "block" : "hidden"} px-10 pt-[10px] pb-[30px] bg-[#F4F6F8] h-[80vh] overflow-y-scroll`}>
          <ul>
            <li className="py-5 border-b border-[#D2DFE6]">
              <a href="/search/status" className="icon-stick-arrow-right right-icon text-sm flex items-center justify-between">ステータスからさがす</a>
            </li>
            <li className="py-5 border-b border-[#D2DFE6]">
              <a href="/ranking" className="icon-stick-arrow-right right-icon text-sm flex items-center justify-between">支援金ランキングからさがす</a>
            </li>
            <li className="py-5 border-b border-[#D2DFE6]">
              <a href="/search/team" className="icon-stick-arrow-right right-icon text-sm flex items-center justify-between">クラブチームからさがす</a>
            </li>
          </ul>
          <div className="py-5 border-b border-[#D2DFE6]">
            <p className="mb-[10px] text-sm">ゆかり検索/キーワード検索</p>
            <div className="text-xs mb-[15px]">
              <p>夢を実現するためにあなたの支援を必要としています。</p>
              <p>まずは、あなたとゆかりのあるチームや選手のプロジェクトを探してみよう！</p>
            </div>
            <div className="relative">
              <input
                type="text"
                placeholder="キーワードを入力してください"
                className="w-full block pl-[10px] py-[15px] placeholder-[#B1C2CC]::placeholder text-sm pr-[30px]"
                onKeyPress={search}
                value={value}
                onChange={handleChange}
              />
              <button type="button" className="w-3 h-3 absolute right-[10px] top-[18px]">
                <img
                  src={CloseBtn}
                  alt="テキストボックスの中身を削除"
                  onClick={resetValue}
                />
              </button>
            </div>
          </div>
          <div className="flex justify-center mt-10">
            <button
              className="icon-close left-icon w-[180px] h-[40px] flex items-center justify-center font-raleway bg-white rounded-default opacity-80"
              onClick={() => closeModal()}
            >
              Close
            </button>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
